import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  FormGroup,
  FormControl,
  Row,
  Col,
  Spinner
} from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import clientAxios from "tools/axiosClient";
const NewUser = () => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const user = {
    name: "",
    lastName: "",
    email: "",
    password: "",
    idRol: "",
    idPosition: ""
  };
  const [ role, setRole ] = useState( null );
  const [ roles, setRoles ] = useState( [ ] );
  const [ position, setPosition ] = useState( null );
  const [ positions, setPositions ] = useState( [ ] );
  const [ loadingRoles, setLoadingRoles ] = useState( true );
  const [ loadingPositions, setLoadingPositions ] = useState( true );
  const UserSchema = Yup.object().shape({
    name: Yup.string().required("El Nombre es obligatorio"),
    lastName: Yup.string().required("El Apellido es obligatorio"),
    email: Yup.string().email("Tiene que ser un correo valido").required("El Correo electronico es obligatorio"),
    idRol: Yup.string().required("EL rol es obligatorio"),
    idPosition: Yup.string().required("La posicion es obligatorio"),
    password: Yup.string().required("La contraseña es obligatorio")
  });
  const handleSubmit = (values) => {
    clientAxios().post(`/v1/api/Users/Create`, values).then( ( { data } ) => {
      if ( data.error === false ) {
        MySwal.fire({
          icon: "success",
          title: "<h2>¡Todo listo!</h2><p>Se ha creado el usuario</p>",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/admin/users");
      }
    });
  };
  const handleRole = ( values ) => {
    const currentRole = roles.filter( role => role.idRol === values )[0];
    setRole( { value:currentRole.idRol, label: currentRole.rolName } );
  }
  const handlePosition = ( values ) =>{
    const currentPosition = positions.filter( role => role.idPosition === values )[0];
    setPosition( { value: currentPosition.idPosition, label: currentPosition.positionDescription } );
  }
  useEffect(() => {
    clientAxios().get(`/v1/api/Roles`).then( ( { data } ) => {
      if (data.error === false) {
        setRoles(data.roles);
      }
    }).finally(() => {
      setLoadingRoles(false);
    } );
    clientAxios().get(`/v1/api/Positions`).then( ( { data } ) => {
      if ( data.error === false ) {
        setPositions( data.positions );
      }
    } ).finally( () => {
      setLoadingPositions(false);
    } );
  }, [ ] );
  return (
    <Fragment>
      <Container fluid>
        <Card className="stacked-form">
          <Formik
            enableReinitialize={true}
            initialValues={user}
            onSubmit={handleSubmit}
            validationSchema={UserSchema}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <Card.Header>
                  <Card.Title as="h4">Crear usuario</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <label>Nombre(s)</label>
                        <FormControl
                          value={values.name}
                          onChange={handleChange}
                          name="name"
                          type="text"
                        />
                        {
                          touched.name && errors.name && (
                            <label className="text-danger">
                              {errors.name}
                            </label>
                          )
                        }
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <label>Apellidos</label>
                        <FormControl
                          value={values.lastName}
                          name="lastName"
                          onChange={handleChange}
                          type="text"
                        />
                        {
                          touched.lastName && errors.lastName && (
                            <label className="text-danger">
                              {errors.lastName}
                            </label>
                          )
                        }
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={loadingRoles ? 5 : 6} sm={12}>
                      <FormGroup>
                        <label>Rol</label>
                        <Select
                          name="idRol"
                          value={role}
                          onChange={ ( e ) => {
                              handleRole(e.value);
                              handleChange( {
                                target: { value: e.value, name: "idRol" },
                              } );
                          } }
                          options={
                            roles.map( ( role ) => ( {
                              value: role.idRol,
                              label: role.rolName,
                            } ) )
                          }
                          placeholder="Selecciona un rol"
                        />
                        {
                          touched.idRol && errors.idRol && (
                            <label className="text-danger">
                              {errors.idRol}
                            </label>
                          )
                        }
                      </FormGroup>
                    </Col>
                    {
                      loadingRoles && (
                        <Col md={1} className="text-center">
                          <br />
                          <Spinner animation="border" role="status" />
                        </Col>
                      )
                    }
                    <Col md={loadingPositions ? 5 : 6} sm={12}>
                      <FormGroup>
                        <label>Posicion</label>
                        <Select
                          name="idPosition"
                          value={ position }
                          onChange={(e) => {
                            handlePosition(e.value);
                            handleChange({
                              target: { value: e.value, name: "idPosition" },
                            });
                          }}
                          options={positions.map((position) => ({
                            value: position.idPosition,
                            label: position.positionDescription,
                          }))}
                          placeholder="Selecciona una posicion"
                        />
                        {
                          touched.idPosition && errors.idPosition && (
                            <label className="text-danger">
                              {errors.idPosition}
                            </label>
                          )
                        }
                      </FormGroup>
                    </Col>
                    {
                      loadingPositions && (
                        <Col md={1} className="text-center">
                          <br />
                          <Spinner animation="border" role="status" />
                        </Col>
                      )
                    }
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <label>Correo</label>
                        <FormControl
                          value={values.email}
                          onChange={handleChange}
                          name="email"
                          type="email"
                        />
                        {
                          touched.email && errors.email && (
                            <label className="text-danger">
                              {errors.email}
                            </label>
                          )
                        }
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <label>Contraseña provicional</label>
                        <FormControl
                          placeholder="Password"
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                        />
                        {
                          touched.password && errors.password && (
                            <label className="text-danger">
                              {errors.password}
                            </label>
                          )
                        }
                      </FormGroup>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col>
                      <Button
                        className="btn-fill"
                        type="submit"
                        variant="secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/admin/users");
                        }}
                      >
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                        Atras
                      </Button>
                    </Col>
                    <Col className="text-right">
                      <Button className="btn-fill" type="submit" variant="info">
                        <i className="fa fa-floppy-o" aria-hidden="true"></i>{" "}
                        Guardar
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </Fragment>
  );
};
export default NewUser;