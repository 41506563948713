import React, { useEffect, useState, Fragment } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

// react-bootstrap components
import { Button, Card, Container, Row, Col, Spinner } from "react-bootstrap";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import clientAxios from "tools/axiosClient";

const Roles = () => {
  const MySwal = withReactContent(Swal);
  let navigate = useNavigate();
  const [roles, setRoles] = useState([]);
  const [registers, setRegisters] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleDelete = (rol) => {
    MySwal.fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "¡Sí, bórralo!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        clientAxios()
          .post(`/v1/api/Roles/Delete/${rol.idRol}`)
          .then(({ data }) => {
            if (data.error === false) {
              clientAxios()
                .get(`/v1/api/Roles`)
                .then(({ data }) => {
                  if (data.error === false) {
                    setRoles(data.roles);
                  }
                })
                .finally(() => {
                  setLoading(false);
                });
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "<h2>¡Todo listo!</h2><p>El rol ha sido eliminado</p>",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          });
      }
    });
  };
  useEffect(() => {
    clientAxios()
      .get(`/v1/api/Roles`)
      .then(({ data }) => {
        if (data.error === false) {
          setRoles(data.roles);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    setRegisters(
      roles.map((prop, key) => {
        return {
          id: prop["idRol"],
          name: prop["rolName"],
          description: prop["rolDescription"],
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                onClick={() => {
                  navigate(`/admin/roles/${prop["idRol"]}`);
                }}
                variant="warning"
                size="sm"
                className="text-warning btn-link edit"
              >
                <i className="fa fa-edit" />
              </Button>{" "}
              {/* use this button to remove the data row */}
              <Button
                onClick={(e) => handleDelete(prop)}
                variant="danger"
                size="sm"
                className="btn-link remove text-danger"
              >
                <i className="fa fa-times" />
              </Button>{" "}
            </div>
          ),
        };
      })
    );
  }, [roles]);
  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card style={{}}>
              <Card.Header>
                <Row>
                  <Col>
                    <Card.Title>Roles</Card.Title>
                    <p className="category">Se muestran todos los Roles.</p>
                  </Col>
                  <Col className="text-right">
                    <Button
                      type="submit"
                      variant="success"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/admin/roles/new");
                      }}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> Nuevo Rol
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {loading === true ? (
                  <Row>
                    <Col className="text-center">
                      <Spinner animation="border" role="status"></Spinner>
                    </Col>
                  </Row>
                ) : (
                  <ReactTable
                    data={registers}
                    columns={[
                      {
                        Header: "Nombre",
                        accessor: "name",
                      },
                      {
                        Header: "Descripcion",
                        accessor: "description",
                      },
                      {
                        Header: "Acciones",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    className="-striped -highlight primary-pagination animate__animated animate__fadeIn"
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Roles;
