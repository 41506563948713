import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import AuthFooter from "components/Footers/AuthFooter.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

// dinamically create auth routes
import routes from "routes.js";

function Auth() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.path}
            key={key}
            element={prop.component}
            exact
          />
        );
      } 
      else {
        return null;
      }
    });
  };
  return (
    <>
      <div className="wrapper wrapper-full-page">
        {/* Navbar */}
        {/* End Navbar */}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/auth/login" replace />} />
        </Routes>
        <AuthFooter />
      </div>
    </>
  );
}

export default Auth;
