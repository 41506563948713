import React, { useState, forwardRef } from "react";
// react-bootstrap components
import {
  Button,
  Card,
  FormGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import ClientAxios from "tools/axiosClient";

const Step3 = forwardRef((props, ref) => {
  const { form, setForm, setGetOrder, setIdOrder } = props;
  const [time, setTime] = useState(form.Time);
  const [timeError, setTimeError] = useState(null);
  const [timeTypeSelect, setTimeTypeSelect] = useState(form.TimeType);
  const [timeTypeError, setTimeTypeError] = useState(null);

  const isValidated = (values) => {
    time === ""
      ? setTimeError(
          <small className="text-danger">Debe asignar un precio.</small>
        )
      : setTimeError(null);
    timeTypeSelect === null
      ? setTimeTypeError(
          <small className="text-danger">Debe seleccionar una moneda.</small>
        )
      : setTimeTypeError(null);
    var tiempo = time !== "";
    var unidadTiempo = timeTypeSelect !== null;
    var valid = tiempo && unidadTiempo;
    if (valid) {
      var model = {
        order: form,
        percentejes: [
          ...[...Array(parseInt(form.PaidExhibits))].map(
            (x, i) => form[`_${i + 1}`]
          ),
        ],
      };
      ClientAxios()
        .post(`/v1/api/ProjectsOrders/Create`, model)
        .then(({ data }) => {
          if (data.error === false) {
            const timeTab = document.getElementById(
              "page-subcategories-tabs-example-tab-time-tab"
            );
            const timeContent = document.getElementById(
              "page-subcategories-tabs-example-tabpane-time-tab"
            );
            const percentTab = document.getElementById(
              "page-subcategories-tabs-example-tab-percent-tab"
            );
            const percentContent = document.getElementById(
              "page-subcategories-tabs-example-tabpane-percent-tab"
            );
            timeTab.classList.remove("active");
            timeContent.classList.remove("active", "show");
            percentTab.classList.add("active");
            percentContent.classList.add("active", "show");
            setGetOrder(true);
            setIdOrder(data.idOrder);
          }
        })
        .catch((e) => {});
    }
  };
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
  }));

  const time_types = [
    { value: "dias", label: "Días" },
    { value: "horas", label: "Horas" },
  ];

  const back = () => {
    const costTab = document.getElementById(
      "page-subcategories-tabs-example-tab-general-cost-tab"
    );
    const costContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-general-cost-tab"
    );
    const timeTab = document.getElementById(
      "page-subcategories-tabs-example-tab-time-tab"
    );
    const timeContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-time-tab"
    );
    costTab.classList.add("active");
    costContent.classList.add("active", "show");
    timeTab.classList.remove("active");
    timeContent.classList.remove("active", "show");
  };
  const handleSubmit = (values) => {
    console.log(values);
    setForm(values);
    isValidated(values);
  };
  return (
    <div className="wizard-step" ref={ref}>
      <Row>
        <Col className="ml-auto mr-auto" md="8">
          <Formik
            enableReinitialize={true}
            initialValues={form}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <Card.Body>
                  <Row className="justify-content-center">
                    <Col lg={4} md={4} sm={12} className="mr-4">
                      <FormGroup>
                        <label>Tiempo</label>
                        <FormControl
                          value={values.Time}
                          onChange={(e) => {
                            handleChange({
                              target: { value: e.target.value, name: "Time" },
                            });
                            setTime(e.target.value);
                          }}
                          name="Time"
                          type="number"
                        />
                        {timeError}
                      </FormGroup>
                    </Col>
                    <Col lg={5} md={5} sm={12}>
                      <FormGroup>
                        <label>Unidad de tiempo</label>
                        <Select
                          name="TimeType"
                          placeholder="Selecciona una unidad"
                          value={time_types.map((time) => {
                            if (time.value === values.TimeType) {
                              return { value: time.value, label: time.label };
                            } else return null;
                          })}
                          options={time_types}
                          onChange={(e) => {
                            handleChange({
                              target: { value: e.value, name: "TimeType" },
                            });
                            setTimeTypeSelect(e.target);
                          }}
                        />
                        {timeTypeError}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-end mt-3">
                    <Col className="col-4">
                      <a
                        className="btn btn-dark"
                        onClick={() => {
                          back();
                        }}
                      >
                        Volver
                      </a>
                    </Col>
                    <Col className="col-5">
                      <Button className="btn btn-info" type="submit">
                        Crear orden y editar tareas
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
});

export default Step3;
