import React, { useState, useEffect, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  FormGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import clientAxios from "tools/axiosClient";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const NewRole = () => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const rol = {
    //idUser:0,
    rolName: "",
    rolDescription: "",
  };
  const rolSchema = Yup.object().shape({
    rolName: Yup.string().required("El Nombre es obligatorio"),
    rolDescription: Yup.string().required("La Descripcion es obligatoria"),
  });
  const handleSubmit = (values) => {
    clientAxios()
      .post(`/v1/api/Roles/Create`, values)
      .then(({ data }) => {
        if (data.error === false) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title:
              "<h2>¡Todo listo!</h2><p>El rol ha sido creado con éxito</p>",
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/admin/roles");
        }
      });
  };
  return (
    <Fragment>
      <Container fluid>
        <Card className="stacked-form">
          <Formik
            enableReinitialize={true}
            initialValues={rol}
            onSubmit={handleSubmit}
            validationSchema={rolSchema}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <Card.Header>
                  <Card.Title as="h4">Crear Rol</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <label>Nombre</label>
                        <FormControl
                          value={values.rolName}
                          onChange={handleChange}
                          name="rolName"
                          type="text"
                        ></FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <label>Descripcion</label>
                        <FormControl
                          value={values.rolDescription}
                          name="rolDescription"
                          onChange={handleChange}
                          type="text"
                        ></FormControl>
                      </FormGroup>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col>
                      <Button
                        className="btn-fill"
                        type="submit"
                        variant="secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/admin/roles");
                        }}
                      >
                        Atras
                      </Button>
                    </Col>
                    <Col className="text-right">
                      <Button className="btn-fill" type="submit" variant="info">
                        Guardar
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </Fragment>
  );
};

export default NewRole;
