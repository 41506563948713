import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Table,
  Row,
  Col,
  FormGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import { Formik, Form } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import clientAxios from "tools/axiosClient";

const Step4 = React.forwardRef((props, ref) => {
  const { form, setForm } = props;
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [project, setProject] = useState({
    idProject: 0,
    projectName: "",
    projectDescription: "",
    price: 0,
    time: 0,
    timeType: "",
    projectType: "",
  });
  const [workItem, setWorkItem] = useState({
    idProject: 1,
    workItemName: "",
    workItemDescription: "",
    time: 0,
    timeType: "",
  });
  const [update, setUpdate] = useState(false);
  const [workItems, setWorkItems] = useState([]);
  const time_types = [
    { value: "dias", label: "Días" },
    { value: "horas", label: "Horas" },
  ];
  const handleSubmit = (values) => {
    clientAxios()
      .post(`/v1/api/WorkItems/Create`, values)
      .then(({ data }) => {
        if (data.error === false) {
          MySwal.fire(
            "Creado!",
            "La tarea ha sido creado con éxito.",
            "success"
          ).then(() => {
            getProject(form.ProjectId);
          });
        }
      });
  };
  const handleSubmitUpdate = (values) => {
    clientAxios()
      .post(`/v1/api/WorkItems/Update`, values)
      .then(({ data }) => {
        if (data.error === false) {
          getProject(form.ProjectId);
          MySwal.fire(
            "Actualizado!",
            "Las tareas han sido actualizadas con éxito.",
            "success"
          ).then(() => {
            setUpdate(false);
            setWorkItem({
              idProject: 1,
              workItemName: "",
              workItemDescription: "",
              time: 0,
              timeType: "",
            });
          });
        }
      });
  };
  const handleEdit = (item) => {
    setWorkItem(item);
    setUpdate(true);
  };
  const handleDelete = (item) => {
    MySwal.fire({
      title: "¿Está seguro de eliminar la tarea?",
      text: "¡No podrá revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
    }).then((result) => {
      if (result.isConfirmed) {
        clientAxios()
          .post(`/v1/api/WorkItems/Delete/${item.idWorkItem}`)
          .then(({ data }) => {
            getProject(form.ProjectId);
            if (data.error === false) {
              MySwal.fire(
                "Eliminado!",
                "La tarea ha sido eliminada.",
                "success"
              );
            }
          });
      }
    });
  };
  const getProject = (id) => {
    console.log('getProject');
    clientAxios().get(`/v1/api/ProjectsOrders/${id}`).then(({ data }) => {
      if (data.error === false) {
        setProject(data.projectOrder);
        setWorkItems(data.workItems);
        console.log(data);
        setWorkItem({ ...workItem, idProject: data.projectOrder.idProject });
      }
    });
  };
  useEffect(() => {
    if (form.ProjectId !== "") {
      console.log(form);
      getProject(form.IdOrder);
    }
  }, [form]);

  const back = () => {
    const percentTab = document.getElementById(
      "page-subcategories-tabs-example-tab-percent-tab"
    );
    const percentContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-percent-tab"
    );
    const timeTab = document.getElementById(
      "page-subcategories-tabs-example-tab-time-tab"
    );
    const timeContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-time-tab"
    );
    percentTab.classList.remove("active");
    percentContent.classList.remove("active", "show");
    timeTab.classList.add("active");
    timeContent.classList.add("active", "show");
  };

  return (
    <Fragment>
      <div className="wizard-step" ref={ref}>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card.Body className="table-responsive p-0">
              <Formik
                enableReinitialize={true}
                initialValues={workItem}
                onSubmit={update ? handleSubmitUpdate : handleSubmit}
              >
                {({ values, errors, touched, handleChange }) => (
                  <Form>
                    <Row className="justify-content-start m-3">
                      <Col md="4">
                        Projecto
                        <Select
                          name="type"
                          value={{
                            value: project.idOrder,
                            label: project.projectByClientDescription,
                          }}
                          onChange={(e) => {
                            handleChange({
                              target: { value: e.value, name: "idProject" },
                            });
                          }}
                          options={[
                            {
                              value: project.idOrder,
                              label: project.projectByClientDescription,
                            },
                          ]}
                          placeholder="Selecciona una tipo"
                          disabled="true"
                        />
                      </Col>
                      <Col md={2} className="mt-4 ml-4">
                        <Button
                          variant="secondary"
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            back();
                          }}
                        >
                          Volver
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
              <Row className="m-3">
                <h4>Lista de tareas</h4>
                <Table className="table-hover table-striped w-full">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Descripción</th>
                      <th>Tiempo</th>
                      <th>Unidad de Tiempo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {workItems.map((i) => (
                      <tr key={i.idWorkItem}>
                        <td>{i.workItemName}</td>
                        <td>{i.workItemDescription}</td>
                        <td>{i.time}</td>
                        <td>{i.timeType}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            </Card.Body>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
});

export default Step4;
