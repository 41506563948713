import axios from 'axios';
import Cookies from 'js-cookie';
let getToken = () => Cookies.get('token') ? Cookies.get('token') : null;
let getAuthorizationHeader = () => `Bearer ${getToken()}`;
const clientAxios = () => {
    return axios.create({
        headers: {
            'Authorization': getAuthorizationHeader()
        }
    });
}
export default clientAxios;