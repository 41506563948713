import React, { useState, useEffect, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  FormGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import clientAxios from "tools/axiosClient";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const EditUser = () => {
  const MySwal = withReactContent(Swal);
  let { id } = useParams();
  const navigate = useNavigate();
  const [role, setRole] = useState({
    idRol: 0,
    rolName: "",
    rolDescription: "",
  });
  const UserSchema = Yup.object().shape({
    rolName: Yup.string().required("El Nombre es obligatorio"),
  });
  const handleSubmit = (values) => {
    clientAxios()
      .post(`/v1/api/Roles/Update`, values)
      .then(({ data }) => {
        if (data.error === false) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title:
              "<h2>¡Todo listo!</h2><p>El rol ha sido editado con éxito</p>",
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/admin/roles");
        }
      });
  };
  useEffect(() => {
    clientAxios()
      .get(`/v1/api/Roles/${id}`)
      .then(({ data }) => {
        if (data.error === false) {
          setRole(data.role);
        }
      });
  }, []);
  return (
    <Fragment>
      <Container fluid>
        <Card className="stacked-form">
          <Formik
            enableReinitialize={true}
            initialValues={role}
            onSubmit={handleSubmit}
            validationSchema={UserSchema}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <Card.Header>
                  <Card.Title as="h4">Editar Rol</Card.Title>
                </Card.Header>
                <Card.Body>
                  <FormControl
                    value={values.idRol}
                    onChange={handleChange}
                    name="idRol"
                    type="hidden"
                  ></FormControl>
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <label>Nombre</label>
                        <FormControl
                          value={values.rolName}
                          onChange={handleChange}
                          name="rolName"
                          type="text"
                        ></FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <label>Descripcion</label>
                        <FormControl
                          value={values.rolDescription}
                          name="rolDescription"
                          onChange={handleChange}
                          type="text"
                        ></FormControl>
                      </FormGroup>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col>
                      <Button
                        className="btn-fill"
                        type="submit"
                        variant="secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/admin/roles");
                        }}
                      >
                        Atrás
                      </Button>
                    </Col>
                    <Col className="text-right">
                      <Button className="btn-fill" type="submit" variant="info">
                        Guardar
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </Fragment>
  );
};

export default EditUser;
