import Login from "pages/Auth/Login.js";
import AdminDashboard from "views/AdminDashboard.js";
import AnalystDashboard from "views/AnalystDashboard.js";
import Charts from "views/Charts.js";

import Users from "pages/Users/Users.js";
import EditUser from "pages/Users/EditUser.js";
import NewUser from "pages/Users/New.js";

import Roles from "pages/Roles/Roles.js";
import EditRole from "pages/Roles/EditRole.js";
import NewRole from "pages/Roles/New.js";

import Positions from "pages/Positions/Positions.js";
import NewPosition from "pages/Positions/New.js";
import EditPosition from "pages/Positions/EditPosition.js";

import PaymentTypes from "pages/PaymentTypes/PaymentTypes.js";
import PaymentTypesEdit from "pages/PaymentTypes/PaymentTypeEdit.js";
import PaymentTypesNew from "pages/PaymentTypes/PaymentTypeNew.js";

import Clients from "pages/Clients/Clients.js";
import ClientsEdit from "pages/Clients/Edit.js";
import ClientsNew from "pages/Clients/New.js";

import Projects from "pages/Projects/Projects.js";
import ProjectsDetails from "pages/Projects/Details.js";
import ProjectsWorkItems from "pages/Projects/WorkItems";
import NewProject from "pages/Projects/New.js";
import EditProject from "pages/Projects/EditProject.js";

import Orders from "pages/Orders/Orders.js";
import New from "pages/Orders/AddOrder/New.js";
import EditOrder from "pages/Orders/EditOrder/Edit.js";
import DetailsOrder from "pages/Orders/Details/Details.js";
import Tasks from "pages/Orders/Tasks.js";

import Invoices from "pages/Invoices/Invoices.js";

import Error403 from "pages/Error/403.js";

var routes = [
  {
    path: "/admindashboard",
    layout: "/admin",
    name: "Admin Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: <AdminDashboard />,
    roles: ["13", "19"],
  },
  {
    path: "/analystdashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: <AnalystDashboard />,
    roles: ["11","13"],
  },
  {
    path: "/orders",
    layout: "/admin",
    name: "Proyectos",
    icon: "nc-icon nc-layers-3",
    component: <Orders />,
    roles: ["11", "13", "19"],
  },
  {
    path: "/orders/new",
    layout: "/admin",
    name: "Nuevo Proyecto",
    component: <New />,
    roles: ["13", "19"],
    sidebar: false,
  },
  {
    path: "/orders/edit/:id",
    layout: "/admin",
    name: "Editar Proyecto",
    component: <EditOrder />,
    roles: ["13", "19"],
    sidebar: false,
  },
  {
    path: "/orders/details/:id",
    layout: "/admin",
    name: "Detalles de Proyecto",
    component: <DetailsOrder />,
    roles: ["13", "19"],
    sidebar: false,
  },
  {
    path: "/orders/tasks/:id",
    layout: "/admin",
    name: "Tareas",
    component: <Tasks />,
    roles: ["11", "13", "19"],
    sidebar: false,
  },
  {
    path: "/login",
    layout: "/auth",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: <Login />,
    roles: ["AllowAnonymous"],
    sidebar: false,
  },
  {
    path: "/invoices",
    layout: "/admin",
    name: "Facturas",
    icon: "nc-icon nc-paper-2",
    component: <Invoices />,
    roles: ["13", "19", "20"],
  },
  {
    path: "/users",
    layout: "/admin",
    name: "Usuarios",
    icon: "nc-icon nc-single-02",
    component: <Users />,
    roles: ["13", "19"],
  },
  {
    path: "/users/:id",
    layout: "/admin",
    name: "Editar usuario",
    component: <EditUser />,
    roles: ["13", "19"],
    sidebar: false,
  },
  {
    path: "/users/new",
    layout: "/admin",
    name: "Nuevo usuario",
    component: <NewUser />,
    roles: ["13", "19"],
    sidebar: false,
  },
  {
    path: "/clients",
    layout: "/admin",
    name: "Clientes",
    icon: "nc-icon nc-single-copy-04",
    component: <Clients />,
    roles: ["13", "19"],
  },
  {
    path: "/clients/new",
    layout: "/admin",
    name: "Nuevo Client",
    icon: "nc-icon nc-chart-pie-35",
    component: <ClientsNew />,
    roles: ["13", "19"],
    sidebar: false,
  },
  {
    path: "/clients/:id",
    layout: "/admin",
    name: "Nuevo Client",
    icon: "nc-icon nc-chart-pie-35",
    component: <ClientsEdit />,
    roles: ["13", "19"],
    sidebar: false,
  },
  {
    path: "/charts",
    layout: "/admin",
    name: "Reportes",
    icon: "nc-icon nc-chart-bar-32",
    component: <Charts />,
    roles: ["13", "19"],
  },
  {
    collapse: true,
    path: "/components",
    name: "Catálogos",
    state: "openComponents",
    icon: "nc-icon nc-app",
    roles: ["13", "19"],
    views: [
      {
        path: "/projects",
        layout: "/admin",
        name: "Tipo de Proyectos",
        mini: "TP",
        component: <Projects />,
        roles: ["13", "19"],
      },
      {
        path: "/projects/details/:id",
        layout: "/admin",
        name: "Detalles del proyecto",
        component: <ProjectsDetails />,
        roles: ["13", "19"],
        sidebar: false,
      },
      {
        path: "/projects/edit/:id",
        layout: "/admin",
        name: "Editar proyecto",
        component: <EditProject />,
        roles: ["13", "19"],
        sidebar: false,
      },
      {
        path: "/projects/worksItems/:id",
        layout: "/admin",
        name: "agregar tareas al proyecto",
        component: <ProjectsWorkItems />,
        roles: ["13", "19"],
        sidebar: false,
      },
      {
        path: "/projects/new",
        layout: "/admin",
        name: "Nuevo proyecto",
        component: <NewProject />,
        roles: ["13", "19"],
        sidebar: false,
      },
      {
        path: "/roles",
        layout: "/admin",
        name: "Roles",
        mini: "R",
        component: <Roles />,
        roles: ["13", "19"],
      },
      {
        path: "/roles/:id",
        layout: "/admin",
        name: "Editar rol",
        component: <EditRole />,
        roles: ["13", "19"],
        sidebar: false,
      },
      {
        path: "/roles/new",
        layout: "/admin",
        name: "Nuevo rol",
        component: <NewRole />,
        roles: ["13", "19"],
        sidebar: false,
      },
      {
        path: "/positions",
        layout: "/admin",
        name: "Puestos",
        mini: "PU",
        component: <Positions />,
        roles: ["13", "19"],
      },
      {
        path: "/positions/:id",
        layout: "/admin",
        name: "Editar puesto",
        icon: "nc-icon nc-chart-pie-35",
        component: <EditPosition />,
        roles: ["13", "19"],
        sidebar: false,
      },
      {
        path: "/positions/new",
        layout: "/admin",
        name: "Nuevo puesto",
        icon: "nc-icon nc-chart-pie-35",
        component: <NewPosition />,
        roles: ["13", "19"],
        sidebar: false,
      },
      {
        path: "/paymenttypes",
        layout: "/admin",
        name: "Tipos de Pagos",
        mini: "TP",
        component: <PaymentTypes />,
        roles: ["13", "19"],
      },
      {
        path: "/paymenttypes/:id",
        layout: "/admin",
        name: "Editar Tipos de pagos",
        icon: "nc-icon nc-chart-pie-35",
        component: <PaymentTypesEdit />,
        roles: ["13", "19"],
        sidebar: false,
      },
      {
        path: "/paymenttypes/new",
        layout: "/admin",
        name: "Nuevo tipo de pago",
        icon: "nc-icon nc-chart-pie-35",
        component: <PaymentTypesNew />,
        roles: ["13", "19"],
        sidebar: false,
      },
    ],
  },
  {
    path: "/403",
    layout: "/Error",
    name: "Sin Permisos",
    component: <Error403 />,
    roles: ["AllowAnonymous"],
    sidebar: false,
  },
];
export default routes;
