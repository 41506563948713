import React, { useEffect } from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";


// core components
import Sidebar from "components/Sidebar/Sidebar.js";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

// dinamically create dashboard routes
import routes from "routes.js";

import image1 from "assets/img/full-screen-image-1.jpg";
import image2 from "assets/img/full-screen-image-2.jpg";
import image3 from "assets/img/full-screen-image-3.jpg";
import image4 from "assets/img/full-screen-image-4.jpg";

import {authStore} from 'store/authStore';
import Authorization from "Authorization";
import Cookies from 'js-cookie';
import clientAxios from "tools/axiosClient";
const Admin = ( ) => {
  let navigate = useNavigate();
  const { user, getUserByToken }=authStore();
  const [sidebarImage, setSidebarImage] = React.useState(image3);
  const [sidebarBackground, setSidebarBackground] = React.useState("black");
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route key={key} element={<Authorization permissions={prop.roles}/>}>
            <Route
              path={prop.path}
              element={prop.component}
            />
          </Route>
        );
      } else {
        return null;
      }
    });
  };
  useEffect(()=>{
    const token = Cookies.get('token');
    if(token===''){
      navigate('/auth/login')
    }
    else{
      if(user!==null)
        getUserByToken();
    }
  },[])
  return (
    <>
      <div className="wrapper">
        <Sidebar
          routes={routes}
          image={sidebarImage}
          background={sidebarBackground}
        />
        <div className="main-panel">
          <AdminNavbar />
          <div className="content">
            <Routes >
            {getRoutes(routes)}
            </Routes>
          </div>
          <AdminFooter />
          <div
            className="close-layer"
            onClick={() =>
              document.documentElement.classList.toggle("nav-open")
            }
          />
        </div>
      </div>
    </>
  );
}

export default Admin;
