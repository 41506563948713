import React, { useState, useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  FormGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import clientAxios from "tools/axiosClient";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const NewProject = () => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const [ projectTypes, setProjectTypes ] = useState( [ ] );
  const [ projectType, setProjectType ] = useState( null );
  const project = {
    projectName: "",
    projectDescription: "",
    price: 0,
    time: 0,
    timeType: "horas",
    projectType: 1,
  };
  const Schema = Yup.object().shape({
    projectName: Yup.string().required("El Nombre es obligatorio"),
    projectDescription: Yup.string().required("La Descripcion es obligatoria"),
  });
  const handleSubmit = (values) => {
    clientAxios().post(`/v1/api/Projects/Create`, { project: values } ).then( ( { data } ) => {
      if (data.error === false) {
        MySwal.fire({
          icon: "success",
          title: "<h2>¡Creado!</h2><p>El Proyecto ha sido creado con éxito</p>",
          showConfirmButton: false,
          timer: 1500,
        })
        navigate("/admin/projects");
      }
    } );
  };
  const time_types = [
    { value: "dias", label: "Días" },
    { value: "horas", label: "Horas" },
  ];
  useEffect(() => {
    clientAxios().get(`/v1/api/Projects/Types`).then(({ data }) => {
      if (data.error === false) {
        setProjectTypes(data.types);
      }
    });
  }, [ ] );
  return (
    <Fragment>
      <Container fluid>
        <Card className="stacked-form">
          <Formik
            enableReinitialize={true}
            initialValues={project}
            onSubmit={handleSubmit}
            validationSchema={Schema}
          >
            { ( { values, errors, touched, handleChange } ) => (
              <Form>
                <Card.Header>
                  <Card.Title as="h4">Crear proyecto</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <label>Nombre</label>
                        <FormControl
                          value={values.projectName}
                          onChange={handleChange}
                          name="projectName"
                          type="text"
                        />
                        { touched.projectName && errors.projectName && ( <label className="text-danger">{errors.projectName}</label> ) }
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <label>Descripción</label>
                        <FormControl
                          value={values.projectDescription}
                          name="projectDescription"
                          onChange={handleChange}
                          type="text"
                        />
                        { touched.projectDescription && errors.projectDescription && ( <label className="text-danger">{errors.projectDescription}</label> ) }
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={3} sm={6}>
                      <FormGroup>
                        <label>Precio</label>
                        <FormControl
                          value={values.price}
                          onChange={handleChange}
                          name="price"
                          type="text"
                        />
                        { touched.price && errors.price && ( <label className="text-danger">{errors.price}</label> ) }
                      </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={6}>
                      <FormGroup>
                        <label>Tiempo</label>
                        <FormControl
                          value={values.time}
                          name="time"
                          onChange={handleChange}
                          type="text"
                        />
                        { touched.time && errors.time && ( <label className="text-danger">{errors.time}</label> ) }
                      </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={6}>
                      <FormGroup>
                        <label>Unidad de tiempo</label>
                        <Select
                          name="timeType"
                          placeholder="Selecciona una unidad"
                          value={ time_types.map( ( time ) => { if(time.value === values.time_type)  return { value: time.value, label: time.label }; } )[0]  }
                          options={time_types}
                          onChange={ (e) => { handleChange( { target: { value: e.value, name: 'timeType' } } ) ;}}
                        />
                        { touched.timeType && errors.timeType && ( <label className="text-danger">{errors.timeType}</label> ) }
                      </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={6}>
                      <FormGroup>
                        <label>Tipo de proyecto</label>
                        <Select
                          name="projectType"
                          value={
                            projectTypes.map((p) => {
                              if (p.idProjectType === values.projectType)
                                return {
                                  value: p.idProjectType,
                                  label: p.projectTypeName,
                                };
                            })[0]
                          }
                          onChange={(e) => {
                            handleChange({
                              target: { value: e.value, name: "projectType" },
                            });
                          }}
                          options={projectTypes.map((p) => ({
                            value: p.idProjectType,
                            label: p.projectTypeName,
                          }))}
                          placeholder="Selecciona un tipo"
                        />
                        { touched.projectType && errors.projectType && ( <label className="text-danger">{errors.projectType}</label> ) }
                      </FormGroup>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col>
                      <Button
                        className="btn-fill"
                        type="submit"
                        variant="secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/admin/projects");
                        }}
                      >
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                        Atras
                      </Button>
                    </Col>
                    <Col className="text-right">
                      <Button className="btn-fill" type="submit" variant="info">
                        <i className="fa fa-floppy-o" aria-hidden="true"></i>{" "}
                        Guardar
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </Fragment>
  );
};

export default NewProject;
