import React, { useEffect, useState, Fragment } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Spinner,
  Tooltip,
  OverlayTrigger
} from "react-bootstrap";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import clientAxios from "tools/axiosClient";

const Projects = () => {
  const MySwal = withReactContent(Swal);
  let navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [registers, setRegisters] = useState([]);
  const [loading, setLoading] = useState(true);
  const pagination = (isLoading) => {
    setLoading(isLoading);
    clientAxios().get(`/v1/api/Projects`).then( ( { data } ) => {
      if (data.error === false) {
        setProjects(data.projects);
      }
    } ).finally( ( ) => {
      setLoading(false);
    } );
  }
  const handleDelete = (project) => {
    MySwal.fire({
      title: "¿Seguro que desea eliminar el proyecto y sus tareas?",
      text: "¡No podrá revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar.",
    }).then((result) => {
      if (result.isConfirmed) {
        clientAxios().post(`/v1/api/Projects/Delete/${project.idProject}`).then(({ data }) => {
          if (data.error === false) {
            pagination(false);
            MySwal.fire({
              icon: "success",
              title:"<h2>¡Eliminado!</h2><p>El proyecto y sus tareas han sido eliminados con éxito</p>",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
      }
    });
  }
  useEffect( ( ) => {
    pagination(true);
  }, [ ] );
  useEffect(() => {
    setRegisters(
      projects.map((prop, key) => {
        const type =
          prop["projectType"] === 1
            ? "A"
            : prop["projectType"] === 2
            ? "B"
            : prop["projectType"] === 3
            ? "C"
            : "AA";
        return {
          id: prop["idProject"],
          name: prop["projectName"],
          price: prop["price"],
          time: prop["time"],
          time_type: prop["timeType"],
          project_type: type,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
               <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="Edit_tooltip">Editar Tareas</Tooltip>}
              >
                <Button
                  onClick={() => {
                    navigate(`/admin/Projects/worksItems/${prop["idProject"]}`);
                  }}
                  variant="success"
                  size="sm"
                  className="text-success btn-link"
                >
                  <i className="fas fa-plus" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="Edit_tooltip">Detalles</Tooltip>}
              >
                <Button
                  onClick={ ( ) => navigate(`/admin/Projects/details/${prop["idProject"]}`) }
                  variant="info"
                  size="sm"
                  className="text-info btn-link"
                >
                  <i className="fa fa-list" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="Edit_tooltip">Editar Proyecto</Tooltip>}
              >
                <Button
                  onClick={ ( ) =>  navigate(`/admin/Projects/edit/${prop["idProject"]}`) }
                  variant="warning"
                  size="sm"
                  className="text-warning btn-link edit"
                >
                  <i className="fa fa-edit" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="Edit_tooltip">Eliminar Proyecto</Tooltip>}
              >
                <Button
                  onClick={ ( ) =>handleDelete(prop)}
                  variant="danger"
                  size="sm"
                  className="btn-link remove text-danger"
                >
                <i className="fa fa-times" />
              </Button>
              </OverlayTrigger>
            </div>
          ),
        };
      })
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ projects ] );
  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card style={{}}>
              <Card.Header>
                <Row>
                  <Col>
                    <Card.Title>Proyectos</Card.Title>
                    <p className="category">Se muestran todos los proyectos.</p>
                  </Col>
                  <Col className="text-right">
                    <Button
                      type="submit"
                      variant="success"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/admin/projects/new");
                      }}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>{' '}
                      Nuevo Proyecto
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {loading === true ? (
                  <Row>
                    <Col className="text-center">
                      <Spinner animation="border" role="status"></Spinner>
                    </Col>
                  </Row>
                ) : (
                  <ReactTable
                    data={registers}
                    columns={[
                      {
                        Header: "Nombre",
                        accessor: "name",
                      },
                      {
                        Header: "Precio",
                        accessor: "price",
                      },
                      {
                        Header: "Tiempo",
                        accessor: "time",
                      },
                      {
                        Header: "Unidad Tiempo",
                        accessor: "time_type",
                      },
                      {
                        Header: "Tipo",
                        accessor: "project_type",
                      },
                      {
                        Header: "Acciones",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    className="-striped -highlight primary-pagination animate__animated animate__fadeIn"
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default Projects;
