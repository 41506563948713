import React, { useState, useEffect, Fragment } from "react";
import { useParams, useNavigate } from 'react-router-dom';
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  FormGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import clientAxios from "tools/axiosClient";
import Select from "react-select";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const PaymentTypeEdit= ( ) => {
    const MySwal = withReactContent(Swal);
    let { id } = useParams();
    const navigate = useNavigate();
    const [ paymentType, setPaymentType ] = useState({
        idPayment:0,
        paymentName: '',
        paymentDescription:''
    });
    const UserSchema = Yup.object().shape({
        paymentName: Yup.string().required('El Nombre es obligatorio'),
    });
    const handleSubmit=(values)=>{
        clientAxios().post(`/v1/api/PaymentTypes/Update`, values).then(({ data }) => {
            if(data.error===false){
                MySwal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                ).then(() => {
                    navigate('/admin/paymenttypes');
                });
            }
        });
    }
    useEffect(() => {
        clientAxios().get(`/v1/api/PaymentTypes/${id}`).then(({ data }) => {
            if(data.error===false){
                setPaymentType(data.paymentType);
            }
        });
    }, [ ] );
    return (
        <Fragment>
            <Container fluid>
                <Card className="stacked-form">
                    <Formik
                        enableReinitialize={ true }
                        initialValues={paymentType}
                        onSubmit={handleSubmit}
                        validationSchema={UserSchema}
                    >
                        {
                            ({values, errors, touched, handleChange})=>(
                                <Form>
                                    <Card.Header>
                                        <Card.Title as="h4">Editar Tipo de pago</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <FormControl
                                            value={values.idPayment }
                                            onChange={handleChange}
                                            name="idPayment"
                                            type="hidden"
                                        ></FormControl>
                                        <Row>
                                            <Col lg={6} md={6} sm={12}>
                                                <FormGroup>
                                                    <label>Nombre</label>
                                                    <FormControl
                                                        value={values.paymentName }
                                                        onChange={handleChange}
                                                        name="paymentName"
                                                        type="text"
                                                    ></FormControl>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={6} md={6} sm={12}>                                  
                                                <FormGroup>
                                                    <label>Descripcion</label>
                                                    <FormControl
                                                        value={values.paymentDescription }
                                                        name="paymentDescription"
                                                        onChange={handleChange}
                                                        type="text"
                                                    ></FormControl>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    
                                    </Card.Body>
                                    <Card.Footer>
                                      <Row>
                                          <Col>
                                              <Button className="btn-fill" type="submit" variant="secondary" onClick={(e)=>{e.preventDefault(); navigate("/admin/paymenttypes")}}>
                                                  Atras
                                              </Button>
                                          </Col>
                                          <Col className="text-right">
                                              <Button className="btn-fill" type="submit" variant="info">
                                                  Guardar
                                              </Button>
                                          </Col>
                                      </Row>
                                    </Card.Footer>
                                </Form>
                            )
                        }
                    </Formik>
                </Card>
            </Container>
    </Fragment>
  );
}

export default PaymentTypeEdit;
