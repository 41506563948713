import React, { useState, useEffect, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
// react-bootstrap components
import {
  Card,
  Table,
  Row,
  Col,
  FormGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import clientAxios from "tools/axiosClient";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { Formik, Form } from "formik";

const Details = () => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  let { id } = useParams();
  const [project, setProject] = useState({
    idProject: 0,
    projectName: "",
    projectDescription: "",
    price: 0,
    time: 0,
    timeType: "",
    projectType: "",
  });
  const [items, setItems] = useState([]);
  const [types, setTypes] = useState([]);
  useEffect(() => {
    clientAxios()
      .get(`/v1/api/Projects/${id}`)
      .then(({ data }) => {
        if (data.error === false) {
          setProject(data.project);
          setItems(data.workItems);
        }
      });
    clientAxios()
      .get(`/v1/api/Projects/Types`)
      .then(({ data }) => {
        if (data.error === false) {
          setTypes(data.types);
        }
      });
  }, []);
  return (
    <Fragment>
      <Row>
        <Col className="col-12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Detalles del proyecto</Card.Title>
              <p className="card-category">
                Se muestran los detalles del proyecto y las tareas requeridas.
              </p>
            </Card.Header>
            <Card.Body className="table-responsive p-0">
              <Formik
                enableReinitialize={true}
                initialValues={project}
                onSubmit={() => {}}
              >
                {({ values, errors, touched, handleChange }) => (
                  <Form>
                    <Row className="justify-content-center m-3">
                      <Col md="4">
                        <FormGroup>
                          <label>Nombre</label>
                          <FormControl
                            type="text"
                            disabled="true"
                            value={values.projectName}
                          ></FormControl>
                        </FormGroup>
                      </Col>
                      <Col className="6">
                        <FormGroup>
                          <label>Descripción</label>
                          <FormControl
                            type="text"
                            disabled="true"
                            value={values.projectDescription}
                          ></FormControl>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="justify-content-center ml-3 mr-3">
                      <Col md="3">
                        <FormGroup>
                          <label>Precio</label>
                          <FormControl
                            type="number"
                            disabled="true"
                            value={values.price}
                          ></FormControl>
                        </FormGroup>
                      </Col>
                      <Col className="3">
                        <FormGroup>
                          <label>Tiempo</label>
                          <FormControl
                            type="number"
                            disabled="true"
                            value={values.time}
                          ></FormControl>
                        </FormGroup>
                      </Col>
                      <Col className="3">
                        <FormGroup>
                          <label>Unidad de tiempo</label>
                          <FormControl
                            type="text"
                            disabled="true"
                            value={values.timeType}
                          ></FormControl>
                        </FormGroup>
                      </Col>
                      <Col className="3">
                        <FormGroup>
                          <label>Tipo</label>
                          <Select
                            name="type"
                            value={types.map((type) => {
                                if (type.idProjectType === values.projectType)
                                    return {
                                        value: type.idProjectType,
                                        label: type.projectTypeName,
                                    };
                            })}
                            isDisabled={true}                            
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="ml-4">
                      <Button
                        variant="secondary"
                        type="submit"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/admin/projects");
                        }}
                      >
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                        Atrás
                      </Button>
                    </Row>
                  </Form>
                )}
              </Formik>
              <Row className="ml-3 mr-3">
                <h4>Lista de tareas</h4>
                <Table className="table-hover table-striped w-full">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Descripción</th>
                      <th>Tiempo</th>
                      <th>Unidad de Tiempo</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((i) => (
                      <tr key={i.idWorkItem}>
                        <td>{i.workItemName}</td>
                        <td>{i.workItemDescription}</td>
                        <td>{i.time}</td>
                        <td>{i.timeType}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Details;
