import React, { useState, useEffect } from 'react';
import Cookies from "js-cookie";
import { Route, Routes, Navigate } from "react-router-dom";
import { useLoading, Grid } from '@agney/react-loading';

import {authStore} from 'store/authStore';
import AuthLayout from "layouts/Auth";
import AdminLayout from "layouts/Admin";
import ErrorLayout from "layouts/Error";
const App = ()=> {
    const { setUser, setToken, getUserByToken, user }=authStore();
    const [ loading, setLoading ] = useState(true);
    const { containerProps, indicatorEl } = useLoading({
        loading: true,
        indicator: <Grid width="100" color="white" />,
    });
    useEffect(()=>{
        getUserByToken().then(({data})=>{
            setUser(data.user);
        }).catch((e)=>{
            setToken(null);
            setUser(null);
            Cookies.remove('token');
        }).finally(()=>{
            setTimeout(() => {
                setLoading(false);
              }, "1000");
        });
    },[getUserByToken, setToken, setUser]);
    return loading === true ? (
        <div className='full-page-background main-content text-center align-middle ' style={{height:'100vh',position: 'relative',  backgroundImage: "url(" + require("assets/img/bg-login.jpg") + ")", backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundAttachment:"fixed"}}>
            <section {...containerProps} style={{position: 'absolute', top: 'calc(50% - 50px)', left:'calc(50% - 50px)' , transform: 'translateY(-50%,-50%)'}}>
                {indicatorEl}
            </section>
        </div>
    ) : (
        <Routes>
            <Route path="/Error/*" element={ <ErrorLayout /> } />
            <Route path="/auth/*" element={ <AuthLayout  /> } />
            <Route path="/admin/*" element={ <AdminLayout /> } />
            <Route path="/" element={((user !== null && user.idRol === 11) ? <Navigate to="/admin/analystdashboard" replace /> : <Navigate to="/admin/admindashboard" replace />)} />
        </Routes>
    );
}
export default App;