import React, { useEffect, useState, Fragment } from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import clientAxios from "tools/axiosClient";

const Invoices = () => {
  const MySwal = withReactContent(Swal);
  const [invoices, setInvoices] = useState([]);
  const [registers, setRegisters] = useState([]);
  const [loading, setLoading] = useState(true);
  const handleStatus = (id) => {
    MySwal.fire({
      title: "cambiar estatus",
      input: "select",
      inputOptions: {
        0: "Creada",
        1: "Pagada",
        2: "Rembolso"
      },
      inputPlaceholder: "Selecciona un estatus",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value !== "") {
            resolve();
          } else {
            resolve("Seleccione un estatus");
          }
        });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const model= {
          id: id,
          status: result.value,
        };
        clientAxios().post(`/v1/api/Invoices/UpdateStatus`,model ).then(({ data }) => {
          if (data.error === false) {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title:
                "<h2>¡Estatus actualizado!</h2><p>El estatus ha sido cambiado con éxito</p>",
              showConfirmButton: false,
              timer: 1500,
            });
            getInvoices();
          }
        });
      }
    });
  };
  const getInvoices=()=>{
    clientAxios().get(`/v1/api/Invoices`).then(({ data }) => {
      if (data.error === false) {
        setInvoices(data.invoices);
      }
    }).finally(() => {
      setLoading(false);
    });
  }
  useEffect(() => {
    setLoading(true);
    getInvoices();
  }, []);
  
  useEffect(() => {
    setRegisters(
      invoices.map((prop, key) => {
        let statusText="";
        if(prop["statusInvoice"]===0){
          statusText="Creada";
        }
        if(prop["statusInvoice"]===1){
          statusText="Pagada";
        }
        if(prop["statusInvoice"]===2){
          statusText="Rembolso";
        }
        return {
          id: prop["idInvoice"],
          projectOrderId: prop["projectOrderId"],
          invoiceReference: prop["invoiceReference"],
          percentage: prop["percentage"] + " %",
          total: "$ " + prop["total"],
          statusInvoice: statusText,
          dueDate: prop["dueDate"],
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="Edit_tooltip">Editar</Tooltip>}
              >
                <Button
                  onClick={() => handleStatus(prop["idInvoice"])}
                  variant="warning"
                  size="sm"
                  className="text-warning btn-link edit"
                >
                  <i className="fa fa-edit" />
                </Button>
              </OverlayTrigger>
            </div>
          ),
        };
      })
    );
  }, [invoices]);

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card style={{}}>
              <Card.Header>
                <Row>
                  <Col>
                    <Card.Title>Facturas</Card.Title>
                    <p className="category">Se muestran todas las facturas.</p>
                  </Col>
                  <Col className="text-right"></Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {loading === true ? (
                  <Row>
                    <Col className="text-center">
                      <Spinner animation="border" role="status"></Spinner>
                    </Col>
                  </Row>
                ) : (
                  <ReactTable
                    data={registers}
                    columns={[
                      {
                        Header: "Id proyecto",
                        accessor: "projectOrderId",
                      },
                      {
                        Header: "No. Factura",
                        accessor: "invoiceReference",
                      },
                      {
                        Header: "Porcentaje",
                        accessor: "percentage",
                      },
                      {
                        Header: "Total",
                        accessor: "total",
                      },
                      {
                        Header: "Status",
                        accessor: "statusInvoice",
                      },
                      {
                        Header: "Fecha límite",
                        accessor: "dueDate",
                      },
                      {
                        Header: "Acciones",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    className="-striped -highlight primary-pagination animate__animated animate__fadeIn"
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Invoices;
