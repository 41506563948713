import React, { useState, useEffect, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  FormGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import clientAxios from "tools/axiosClient";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const EditProject = () => {
  const MySwal = withReactContent(Swal);
  let { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState({
    idProject: 0,
    projectName: "",
    projectDescription: "",
    price: 0,
    time: 0,
    timeType: "",
    projectType: 0,
  });
  const Schema = Yup.object().shape({
    projectName: Yup.string().required("El Nombre es obligatorio"),
  });
  const handleSubmit = (values) => {
    clientAxios()
      .post(`/v1/api/Projects/Update`, values)
      .then(({ data }) => {
        if (data.error === false) {
          MySwal.fire(
            "Editado!",
            "El proyecto ha sido editado con éxito.",
            "success"
          ).then(() => {
            navigate("/admin/projects");
          });
        } else {
          MySwal.fire("Ups!", "Ha ocurrido un error: " + data.message, "error");
        }
      });
  };
  useEffect(() => {
    clientAxios()
      .get(`/v1/api/Projects/${id}`)
      .then(({ data }) => {
        if (data.error === false) {
          setProject(data.project);
        }
      });
  }, [id]);

  const [projectTypes, setProjectTypes] = useState([]);

  useEffect(() => {
    clientAxios()
      .get(`/v1/api/Projects/Types`)
      .then(({ data }) => {
        if (data.error === false) {
          setProjectTypes(data.types);
        }
      });
  }, []);

  const time_types = [
    { value: "dias", label: "Días" },
    { value: "horas", label: "Horas" },
  ];

  return (
    <Fragment>
      <Container fluid>
        <Card className="stacked-form">
          <Formik
            enableReinitialize={true}
            initialValues={project}
            onSubmit={handleSubmit}
            validationSchema={Schema}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <Card.Header>
                  <Card.Title as="h4">Editar proyecto</Card.Title>
                </Card.Header>
                <Card.Body>
                  <FormControl
                    value={values.idProject}
                    onChange={handleChange}
                    name="idProject"
                    type="hidden"
                  ></FormControl>
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <label>Nombre de Proyecto</label>
                        <FormControl
                          value={values.projectName}
                          onChange={handleChange}
                          name="projectName"
                          type="text"
                        ></FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <label>Descripción</label>
                        <FormControl
                          value={values.projectDescription}
                          name="projectDescription"
                          onChange={handleChange}
                          type="text"
                        ></FormControl>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={3} sm={6}>
                      <FormGroup>
                        <label>Precio</label>
                        <FormControl
                          value={values.price}
                          onChange={handleChange}
                          name="price"
                          type="text"
                        ></FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={6}>
                      <FormGroup>
                        <label>Tiempo</label>
                        <FormControl
                          value={values.time}
                          name="time"
                          onChange={handleChange}
                          type="text"
                        ></FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={6}>
                      <FormGroup>
                        <label>Unidad de tiempo</label>
                        <Select
                          name="timeType"
                          placeholder="Selecciona una unidad"
                          value={
                            time_types.map((time) => {
                              if (time.value === values.timeType)
                                return { value: time.value, label: time.label };
                            })
                          }
                          options={time_types}
                          onChange={(e) => {
                            handleChange({
                              target: { value: e.value, name: "timeType" },
                            });
                          }}
                        ></Select>
                      </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={6}>
                      <FormGroup>
                        <label>Tipo de proyecto</label>
                        <Select
                          name="projectType"
                          value={projectTypes.map((p) => {
                            if (p.idProjectType === values.projectType)
                              return {
                                value: p.idProjectType,
                                label: p.projectTypeName,
                              };
                          })}
                          onChange={(e) => {
                            handleChange({
                              target: { value: e.value, name: "projectType" },
                            });
                          }}
                          options={projectTypes.map((p) => ({
                            value: p.idProjectType,
                            label: p.projectTypeName,
                          }))}
                          placeholder="Selecciona un tipo"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col>
                      <Button
                        className="btn-fill"
                        type="submit"
                        variant="secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/admin/projects");
                        }}
                      >
                        Atrás
                      </Button>
                    </Col>
                    <Col className="text-right">
                      <Button className="btn-fill" type="submit" variant="info">
                        Guardar
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </Fragment>
  );
};

export default EditProject;
