import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";
// react-bootstrap components
import {
  Button,
  Card,
  FormGroup,
  FormControl,
  Row,
  Col,
  FormCheck,
} from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import clientAxios from "tools/axiosClient";
import Select from "react-select";

const Step1 = forwardRef((props, ref) => {
  const { form, setForm } = props;
  const [clientSelect, setClientSelect] = useState(null);
  const [iguala, setIguala] = useState(false);
  const [clientError, setClientError] = useState(null);
  const [description, setDesc] = useState("");
  const [descError, setDescError] = useState(null);
  const [userSelect, setUserSelect] = useState(null);
  const [userError, setUserError] = useState(null);
  const [projectSelect, setProjectSelect] = useState(null);
  const [projectError, setProjectError] = useState(null);
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);

  const isValidated = () => {
    clientSelect === null
      ? setClientError(
          <small className="text-danger">Debe seleccionar un cliente.</small>
        )
      : setClientError(null);
    userSelect === null
      ? setUserError(
          <small className="text-danger">Debe seleccionar un analista.</small>
        )
      : setUserError(null);
    projectSelect === null
      ? setProjectError(
          <small className="text-danger">Debe seleccionar un proyecto.</small>
        )
      : setProjectError(null);
    description === ""
      ? setDescError(
          <small className="text-danger">Debe ingresar una descripción..</small>
        )
      : setDescError(null);
    var client = clientSelect !== null;
    var desc = description !== "";
    var user = userSelect !== null;
    var project = projectSelect !== null;
    var valid = client && desc && user && project;
    if (valid) {
      const infoTab = document.getElementById(
        "page-subcategories-tabs-example-tab-general-information-tab"
      );
      const infoContent = document.getElementById(
        "page-subcategories-tabs-example-tabpane-general-information-tab"
      );
      const costTab = document.getElementById(
        "page-subcategories-tabs-example-tab-general-cost-tab"
      );
      const costContent = document.getElementById(
        "page-subcategories-tabs-example-tabpane-general-cost-tab"
      );
      infoTab.classList.remove("active");
      infoContent.classList.remove("active", "show");
      costTab.classList.add("active");
      costContent.classList.add("active", "show");
    }
  };
  useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
  }));
  const handleSubmit = (values) => {
    let current = projects.filter((p) => p.idProject === values.ProjectId)[0];
    const price = values.Iguala === true ? 0 : current.price;
    console.table(projects);
    console.log(price);
    setForm({
      ...form,
      ClientId: values.ClientId,
      ProjectId: values.ProjectId,
      UserId: values.UserId,
      Iguala: values.Iguala,
      ProjectByClientDescription: values.ProjectByClientDescription,
      Time: current.time,
      TimeType: current.timeType,
      Price: price,
    });
    isValidated();
  };

  useEffect(() => {
    clientAxios()
      .get(`/v1/api/Clients`)
      .then(({ data }) => {
        if (data.error === false) {
          setClients(data.clients);
        }
      });
    clientAxios()
      .get(`/v1/api/Users`)
      .then(({ data }) => {
        if (data.error === false) {
          setUsers(data.users);
        }
      });
    clientAxios()
      .get(`/v1/api/Projects`)
      .then(({ data }) => {
        if (data.error === false) {
          setProjects(data.projects);
        }
      });
  }, []);

  return (
    <div className="wizard-step" ref={ref}>
      <Row>
        <Col className="ml-auto mr-auto" md="10">
          <Formik
            enableReinitialize={true}
            initialValues={form}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <Card.Body>
                  <Row className="justify-content-center">
                    <Col lg={4} md={4} sm={12}>
                      <FormGroup>
                        <label>Cliente</label>
                        <Select
                          name="ClientId"
                          value={clients.map((p) => {
                            if (p.idClient === values.ClientId) {
                              return {
                                value: p.idClient,
                                label: p.clientName,
                              };
                            } else return null;
                          })}
                          onChange={(e) => {
                            handleChange({
                              target: { value: e.value, name: "ClientId" },
                            });
                            let currentClient = clients.filter(
                              (p) => p.idClient === e.value
                            )[0];
                            setClientSelect(e.target);
                            handleChange({
                              target: {
                                value: currentClient.iguala,
                                name: "Iguala",
                              },
                            });
                          }}
                          options={clients.map((p) => ({
                            value: p.idClient,
                            label: p.clientName,
                          }))}
                          placeholder="Selecciona un cliente"
                        />
                        {clientError}
                      </FormGroup>
                    </Col>
                    <Col lg={2} md={2} sm={12}>
                      <br></br>
                      <FormCheck className="mt-2">
                        <FormCheck.Label>
                          <FormCheck.Input
                            checked={iguala === true ? true : values.Iguala}
                            value={values.Iguala}
                            checked={values.Iguala}
                            type="checkbox"
                            name="Iguala"
                            onChange={handleChange}
                            autoComplete="true"
                          />
                          <span className="form-check-sign"></span>
                          <label>Iguala</label>
                        </FormCheck.Label>
                      </FormCheck>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                      <FormGroup>
                        <label>Analista</label>
                        <Select
                          name="UserId"
                          value={users.map((p) => {
                            if (p.idUser === values.UserId) {
                              return {
                                value: p.idUser,
                                label: p.name,
                              };
                            } else {
                              return null;
                            }
                          })}
                          onChange={(e) => {
                            handleChange({
                              target: { value: e.value, name: "UserId" },
                            });
                            setUserSelect(e.target);
                          }}
                          options={users.map((p) => ({
                            value: p.idUser,
                            label: p.name,
                          }))}
                          placeholder="Selecciona un analista"
                        />
                        {userError}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg={4} md={4} sm={12} className="mr-4">
                      <FormGroup>
                        <label>Proyecto</label>
                        <Select
                          name="ProjectId"
                          value={projects.map((p) => {
                            if (p.idProject === values.ProjectId) {
                              return {
                                value: p.idProject,
                                label: p.projectName,
                              };
                            } else {
                              return null;
                            }
                          })}
                          onChange={(e) => {
                            handleChange({
                              target: { value: e.value, name: "ProjectId" },
                            });
                            setProjectSelect(e.target);
                          }}
                          options={projects.map((p) => ({
                            value: p.idProject,
                            label: p.projectName,
                          }))}
                          placeholder="Selecciona un proyecto"
                        />
                        {projectError}
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <label>Descripción</label>
                        <FormControl
                          value={values.ProjectByClientDescription}
                          onChange={(e) => {
                            handleChange({
                              target: {
                                value: e.target.value,
                                name: "ProjectByClientDescription",
                              },
                            });
                            setDesc(e.target.value);
                          }}
                          name="ProjectByClientDescription"
                          type="text"
                          autoComplete="true"
                        ></FormControl>
                        {descError}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-end">
                    <Col className="col-2">
                      <Button className="btn btn-info mb-4 mt-4" type="submit">
                        Continuar
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
});

export default Step1;
