import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

//Tabs
import Step1 from "pages/Orders/AddOrder/Step1";
import Step2 from "pages/Orders/AddOrder/Step2";
import Step3 from "pages/Orders/AddOrder/Step3";
import Step4 from "pages/Orders/AddOrder/Step4";

// react-bootstrap components
import { Card, Nav, Container, Row, Col, Tab } from "react-bootstrap";

const New = () => {
  let navigate = useNavigate();
  const [form, setForm] = useState({
    ClientId: "",
    ProjectId: "",
    UserId: "",
    Iguala: false,
    ProjectByClientDescription: "",
    Price: 0,
    Currency: "",
    ExchangeRate: null,
    PaymentMethodId: "",
    Time: 0,
    TimeType: "",
    Invoice: false,
    PaidExhibits: "",
  });
  const [getOrder, setGetOrder] = useState(false);
  const [idOrder, setIdOrder] = useState(null);
  return (
    <Fragment>
      <Container fluid>
        <Card>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <Tab.Container
                id="page-subcategories-tabs-example"
                defaultActiveKey="general-information-tab"
                className="packages-container"
              >
                <Nav
                  role="tablist"
                  variant="tabs"
                  className="justify-content-center packages-tab"
                >
                  <Nav.Item className="package-tab-item pl-4 pr-4">
                    <Nav.Link
                      eventKey="general-information-tab"
                      className="border-0 bg-transparent package-tab-content"
                    >
                      <i className="mr-2"></i>
                      Información general
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="package-tab-item pl-4 pr-4">
                    <Nav.Link
                      eventKey="general-cost-tab"
                      className="border-0 bg-transparent package-tab-content"
                    >
                      <i className="mr-2"></i>
                      Tarifa general
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="package-tab-item pl-4 pr-4">
                    <Nav.Link
                      eventKey="time-tab"
                      className="border-0 bg-transparent package-tab-content"
                    >
                      <i className="mr-2"></i>
                      Tiempo
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="package-tab-item pl-4 pr-4">
                    <Nav.Link
                      eventKey="percent-tab"
                      className="border-0 bg-transparent package-tab-content"
                    >
                      <i className="mr-2"></i>
                      Tareas
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="general-information-tab">
                    <Step1 form={form} setForm={setForm} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="general-cost-tab">
                    <Step2 form={form} setForm={setForm} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="time-tab">
                    <Step3
                      form={form}
                      setForm={setForm}
                      setGetOrder={setGetOrder}
                      setIdOrder={setIdOrder}
                    />
                  </Tab.Pane>
                  <Tab.Pane eventKey="percent-tab">
                    <Step4
                      form={form}
                      setForm={setForm}
                      getOrder={getOrder}
                      idOrder={idOrder}
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
          <Row className="justify-content-start">
            <Col className="col-2">
              <a
                href="..."
                className="btn btn-danger m-4"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/admin/orders");
                }}
              >
                ◄ Cancelar
              </a>
            </Col>
          </Row>
        </Card>
      </Container>
    </Fragment>
  );
};

export default New;
