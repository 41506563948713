import React, { useState, useEffect, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  FormGroup,
  FormControl,
  FormCheck,
  Row,
  Col,
} from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import clientAxios from "tools/axiosClient";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const EditClient = () => {
  const MySwal = withReactContent(Swal);
  let { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState({
    clientName: "",
    clientLastName: "",
    clientPhone: "",
    clientCell: "",
    clientEmail: "",
    razonSocial: "",
    rfc: "",
    address: "",
    postalCode: "",
    city: "",
    state: "",
    iguala: false,
  });
  const clientSchema = Yup.object().shape({
    // clientName: Yup.string().required("El Nombre es obligatorio"),
    // clientLastName: Yup.string().required("El Apellido es obligatoria"),
    // clientPhone: Yup.number().required("El Telefono es obligatorio"),
    clientCell: Yup.number().required("El Celular es obligatorio"),
    clientEmail: Yup.string().email("Ingrese un correo válido"),
    // .required("El correo es obligatorio"),
    razonSocial: Yup.string().required("La razón social es obligatoria"),
    rfc: Yup.string().required("El rfc es obligatorio"),
    address: Yup.string().required("La dirección es obligatoria"),
    postalCode: Yup.string().required("El código postal es obligatorio"),
    city: Yup.string().required("La ciudad es obligatoria"),
    state: Yup.string().required("El estado es obligatorio"),
    iguala: Yup.boolean(),
  });
  const handleSubmit = (values) => {
    clientAxios()
      .post(`/v1/api/Clients/Update`, values)
      .then(({ data }) => {
        if (data.error === false) {
          MySwal.fire({
            //position: "top-end",
            icon: "success",
            title:
              "<h2>¡Editado!</h2><p>El cliente ha sido editado con éxito</p>",
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/admin/clients");
        }
      });
  };
  useEffect(() => {
    clientAxios()
      .get(`/v1/api/Clients/${id}`)
      .then(({ data }) => {
        if (data.error === false) {
          setClient(data.client);
        }
      });
  }, [id]);
  return (
    <Fragment>
      <Container fluid>
        <Card className="stacked-form">
          <Formik
            enableReinitialize={true}
            initialValues={client}
            onSubmit={handleSubmit}
            validationSchema={clientSchema}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <Card.Header>
                  <Card.Title as="h4">Editar Cliente</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col lg={4} md={4} sm={12}>
                      <FormGroup>
                        <label>Razón Social</label>
                        <FormControl
                          value={values.razonSocial}
                          name="razonSocial"
                          onChange={handleChange}
                        />
                        {touched.razonSocial && errors.razonSocial && (
                          <label className="text-danger">
                            {errors.razonSocial}
                          </label>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                      <FormGroup>
                        <label>RFC</label>
                        <FormControl
                          value={values.rfc}
                          name="rfc"
                          onChange={handleChange}
                        />
                        {touched.rfc && errors.rfc && (
                          <label className="text-danger">{errors.rfc}</label>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={12}>
                      <FormGroup>
                        <label>Telefono</label>
                        <FormControl
                          value={values.clientPhone}
                          name="clientPhone"
                          onChange={handleChange}
                        />
                        {touched.clientPhone && errors.clientPhone && (
                          <label className="text-danger">
                            {errors.clientPhone}
                          </label>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg={1} md={1}>
                      <FormCheck className="mt-4">
                        <FormCheck.Label>
                          <FormCheck.Input
                            onChange={handleChange}
                            name="iguala"
                            checked={values.iguala}
                          />
                          <span className="form-check-sign"></span>
                          Iguala
                        </FormCheck.Label>
                      </FormCheck>
                      {touched.iguala && errors.iguala && (
                        <label className="text-danger">{errors.iguala}</label>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={5} md={5} sm={12}>
                      <FormGroup>
                        <label>Dirección</label>
                        <FormControl
                          value={values.address}
                          name="address"
                          onChange={handleChange}
                        />
                        {touched.address && errors.address && (
                          <label className="text-danger">
                            {errors.address}
                          </label>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg={2} md={2} sm={12}>
                      <FormGroup>
                        <label>Código Postal</label>
                        <FormControl
                          value={values.postalCode}
                          name="postalCode"
                          onChange={handleChange}
                        />
                        {touched.postalCode && errors.postalCode && (
                          <label className="text-danger">
                            {errors.postalCode}
                          </label>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg={2} md={2} sm={12}>
                      <FormGroup>
                        <label>Ciudad</label>
                        <FormControl
                          value={values.city}
                          name="city"
                          onChange={handleChange}
                        />
                        {touched.city && errors.city && (
                          <label className="text-danger">{errors.city}</label>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={12}>
                      <FormGroup>
                        <label>Estado</label>
                        <FormControl
                          value={values.state}
                          name="state"
                          onChange={handleChange}
                        />
                        {touched.state && errors.state && (
                          <label className="text-danger">{errors.state}</label>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <h4>Información de contacto</h4>
                  </Row>
                  <Row>
                    <Col lg={4} md={4} sm={12}>
                      <FormGroup>
                        <label>Nombre</label>
                        <FormControl
                          value={values.clientName}
                          onChange={handleChange}
                          name="clientName"
                          type="text"
                        />
                        {touched.clientName && errors.clientName && (
                          <label className="text-danger">
                            {errors.clientName}
                          </label>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                      <FormGroup>
                        <label>Apellidos</label>
                        <FormControl
                          value={values.clientLastName}
                          name="clientLastName"
                          onChange={handleChange}
                          type="text"
                        />
                        {touched.clientLastName && errors.clientLastName && (
                          <label className="text-danger">
                            {errors.clientLastName}
                          </label>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                      <FormGroup>
                        <label>Correo electronico</label>
                        <FormControl
                          value={values.clientEmail}
                          name="clientEmail"
                          onChange={handleChange}
                        />
                        {touched.clientEmail && errors.clientEmail && (
                          <label className="text-danger">
                            {errors.clientEmail}
                          </label>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={3} sm={12}>
                      <FormGroup>
                        <label>Celular</label>
                        <FormControl
                          value={values.clientCell}
                          name="clientCell"
                          onChange={handleChange}
                        />
                        {touched.clientCell && errors.clientCell && (
                          <label className="text-danger">
                            {errors.clientCell}
                          </label>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col>
                      <Button
                        className="btn-fill"
                        type="submit"
                        variant="secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/admin/clients");
                        }}
                      >
                        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
                        Atras
                      </Button>
                    </Col>
                    <Col className="text-right">
                      <Button className="btn-fill" type="submit" variant="info">
                        <i className="fa fa-floppy-o" aria-hidden="true"></i>{" "}
                        Guardar
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </Fragment>
  );
};

export default EditClient;
