import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  Table,
  Row,
  Col,
  FormGroup,
  FormControl,
  Button,
  Container,
} from "react-bootstrap";
import Select from "react-select";
import { Formik, Form } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";

import "react-datetime/css/react-datetime.css";
import clientAxios from "tools/axiosClient";

const Tasks = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  const [project, setProject] = useState({
    idOrder: 0,
    projectName: "",
    projectDescription: "",
    price: 0,
    time: 0,
    timeType: "",
    projectType: "",
  });
  const [workItem, setWorkItem] = useState({
    projectOrderId: 0,
    workItemName: "",
    workItemDescription: "",
    time: 0,
    timeType: "",
    userId: 0,
  });

  const [update, setUpdate] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [updateTime, setUpdateTime] = useState(0);
  const [taskTime, setTaskTime] = useState(0);
  const [workItems, setWorkItems] = useState([]);
  const [users, setUsers] = useState([]);

  const time_types = [
    { value: "dias", label: "Días" },
    { value: "semanas", label: "Semanas" },
  ];
  const handleSubmit = (values) => {
    //Verificamos si aun no se excede el tiempo máximo designado al proyecto
    if (currentTime < project.time) {
      clientAxios()
        .post(`/v1/api/WorkItemsByOrder/Create`, values)
        .then(({ data }) => {
          if (data.error === false) {
            Toast.fire({
              icon: "success",
              title: "Tarea creada con éxito.",
            }).then(() => {
              getProject(id);
              setProject({
                ...project,
                idOrder: 0,
                idWorkItemOrder: 0,
                workItemName: "",
                time: "",
                timeType: "",
                userId: 0,
              });
              setWorkItem({
                projectOrderId: 0,
                workItemName: "",
                workItemDescription: "",
                time: 0,
                timeType: "",
                userId: 0,
              });
              getWorkItems();
              setUpdate(false);
            });
          }
        });
    } else {
      MySwal.fire({
        title:
          "<h2>¡Sin tiempo!</h2><p>El tiempo máximo del proyecto no puede ser excedido.</p>",
        icon: "warning",
        showConfirmButton: true,
      });
    }
  };
  const handleSubmitUpdate = (values) => {
    // values.startDate = values.startDate == undefined ? undefined : values.startDate.format("YYYY-MM-DD HH:mm:ss");
    // values.finishDate = values.finishDate == undefined ? undefined : values.finishDate.format("YYYY-MM-DD HH:mm:ss");
    // values.dueDate = values.dueDate == undefined ? undefined : values.dueDate.format("YYYY-MM-DD HH:mm:ss");
    //Verificamos si aún no se excede el tiempo máximo designado al proyecto
    const time = parseInt(updateTime) + parseInt(values.time);
    setUpdateTime(time);
    if (time <= project.time) {
      values.idProject = project.idProject;
      clientAxios()
        .post(`/v1/api/WorkItemsByOrder/Update`, values)
        .then(({ data }) => {
          if (data.error === false) {
            getProject(id);
            Toast.fire({
              icon: "success",
              title: "Tarea actualizada con éxito.",
            }).then(() => {
              getWorkItems();
              setUpdate(false);
              setWorkItem({
                projectOrderId: 0,
                workItemName: "",
                time: 0,
                timeType: "",
                userId: 0,
                startDate: moment(),
                endDate: moment(),
                dueDate: moment(),
              });
              // setProject({
              //   ...project,
              //   idOrder: 0,
              //   idWorkItemOrder: 0,
              //   workItemName: "",
              //   time: "",
              //   timeType: "",
              // });`
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      const time = parseInt(project.time) - parseInt(taskTime);
      setUpdateTime(time);
      MySwal.fire({
        title:
          "<h2>¡Sin tiempo!</h2><p>El tiempo máximo del proyecto no puede ser excedido.</p>",
        icon: "warning",
        showConfirmButton: true,
      });
    }
  };
  const handleEdit = (item) => {
    setUpdateTime(currentTime - item.time);
    setTaskTime(item.time);
    setWorkItem(item);
    setUpdate(true);
    // setProject({
    //   ...project,
    //   idOrder: item.projectOrderId,
    //   idWorkItemOrder: item.idWorkItemOrder,
    //   workItemName: item.workItemName,
    //   time: item.time,
    //   timeType: item.timeType,
    // });
  };
  const handleDelete = (item) => {
    MySwal.fire({
      title: "¿Está seguro de eliminar la tarea?",
      text: "¡No podrá revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
    }).then((result) => {
      if (result.isConfirmed) {
        clientAxios()
          .post(`/v1/api/WorkItemsByOrder/Delete/${item.idWorkItemOrder}`)
          .then(({ data }) => {
            if (data.error === false) {
              Toast.fire({
                icon: "success",
                title: "Tarea eliminada con éxito.",
              });
              getWorkItems();
              getProject(id);
            }
          });
      }
    });
  };
  const getWorkItems = () => {
    clientAxios()
      .get(`/v1/api/ProjectsOrders/WorkItems/${id}`)
      .then(({ data }) => {
        if (data.error === false) {
          setWorkItems(data.workItems);
          //setWorkItem({ ...workItem, idProject: data. });
        }
      });
  };
  const getProject = (id) => {
    clientAxios()
      .get(`/v1/api/ProjectsOrders/${id}`)
      .then(({ data }) => {
        if (data.error === false) {
          setProject(data.projectOrder);
          setWorkItems(data.workItems);
          setWorkItem({
            ...workItem,
            projectOrderId: data.projectOrder.idOrder,
            userId: data.projectOrder.userId,
            timeType: data.projectOrder.timeType,
          });
          setCurrentTime(
            data.workItems.reduce(
              (acc, element) => acc + parseInt(element.time),
              0
            )
          );
        }
      });
  };

  const handleStatus = async (id) => {
    let reason = "";

    const changeStatus = await MySwal.fire({
      title: "cambiar estatus",
      input: "select",
      inputOptions: {
        Asignado: "Asignado",
        Iniciado: "Iniciado",
        Pendiente: "Pendiente",
        Cerrado: "Cerrado",
      },
      inputPlaceholder: "Selecciona un estatus",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value !== "") {
            resolve();
          } else {
            resolve("Seleccione un estatus");
          }
        });
      },
    });

    if (changeStatus.isConfirmed) {
      if (changeStatus.value === "Pendiente") {
        const pending = await MySwal.fire({
          title: "Motivo",
          input: "text",
          inputPlaceholder: "Especifíque un motivo",
          showCancelButton: true,
          allowOutsideClick: false,
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value !== "") {
                resolve();
              } else {
                resolve("Debe especificar un motivo.");
              }
            });
          },
        });
        if (pending.isDenied) {
          reason = undefined;
        } else {
          reason = pending.value;
        }
      }

      if (reason !== undefined) {
        clientAxios()
          .post(`/v1/api/WorkItemsByOrder/UpdateStatus`, {
            id: id,
            status: changeStatus.value,
            reason: reason,
          })
          .then(({ data }) => {
            if (data.error === false) {
              Toast.fire({
                icon: "success",
                title: "Estatus actualizado con éxito",
              });
              getWorkItems();
            }
          });
      }
    }
  };

  useEffect(() => {
    if (id !== "") {
      getProject(id);
    }
    clientAxios()
      .get(`/v1/api/Users`)
      .then(({ data }) => {
        if (data.error === false) {
          setUsers(data.users);
        }
      });
  }, []);

  return (
    <Fragment>
      <div className="wizard-step">
        <Container fluid>
          <Card>
            <Card.Body className="table-responsive p-0">
              <Row>
                <Col className="ml-auto mr-auto" md="12">
                  <Formik
                    enableReinitialize={true}
                    initialValues={workItem}
                    onSubmit={update ? handleSubmitUpdate : handleSubmit}
                  >
                    {({ values, errors, touched, handleChange }) => (
                      <Form>
                        <Row className="justify-content-left m-3">
                          <Col md="12">
                            <FormGroup>
                              <Select
                                name="ProjectName"
                                value={{
                                  value: project.idOrder,
                                  label: project.projectByClientDescription,
                                }}
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      value: e.value,
                                      name: "idProject",
                                    },
                                  });
                                }}
                                options={[
                                  {
                                    value: project.idOrder,
                                    label: project.projectByClientDescription,
                                  },
                                ]}
                                placeholder="Selecciona una tipo"
                                disabled="true"
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="m-3">
                          <label>Actualizar o agregar tareas</label>
                          <label>{update.valueOf}</label>
                        </Row>
                        <Row className="m-3">
                          <Col md="4">
                            <FormGroup>
                              <label>Nombre</label>
                              <FormControl
                                type="text"
                                value={values.workItemName}
                                name="workItemName"
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col lg={3} md={3} sm={12}>
                            <FormGroup>
                              <label>Analista</label>
                              <Select
                                name="UserId"
                                value={users
                                  .filter(
                                    (user) => user.idUser === values.userId
                                  )
                                  .map((p) => {
                                    return {
                                      value: p.idUser,
                                      label: p.name,
                                    };
                                  })}
                                onChange={(e) => {
                                  handleChange({
                                    target: { value: e.value, name: "userId" },
                                  });
                                }}
                                options={users.map((p) => ({
                                  value: p.idUser,
                                  label: p.name,
                                }))}
                                placeholder="Selecciona un analista"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="2">
                            <FormGroup>
                              <label>Tiempo</label>
                              <FormControl
                                type="number"
                                value={values.time}
                                name="time"
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <label>Tipo de tiempo</label>
                              <Select
                                name="timeType"
                                placeholder="Selecciona una unidad"
                                value={time_types
                                  .filter(
                                    (time) => time.value === values.timeType
                                  )
                                  .map((time) => {
                                    return {
                                      value: time.value,
                                      label: time.label,
                                    };
                                  })}
                                options={time_types}
                                onChange={(e) => {
                                  handleChange({
                                    target: {
                                      value: e.value,
                                      name: "timeType",
                                    },
                                  });
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="m-3">
                          <Col md={4}>
                            <Button
                              variant="secondary"
                              onClick={(e) => {
                                navigate("/admin/orders");
                              }}
                            >
                              <i
                                className="fa fa-arrow-left"
                                aria-hidden="true"
                              ></i>{" "}
                              Atrás
                            </Button>
                          </Col>
                          <Col md={4} className="text-center">
                            <h3>
                              <span className="text-info">
                                {currentTime} {project.timeType}
                              </span>{" "}
                              /{" "}
                              <span className="text-success">
                                {project.time} {project.timeType}{" "}
                                <small className="text-success">
                                  disponibles
                                </small>
                              </span>
                            </h3>
                          </Col>
                          <Col md={4} className="text-right">
                            {update ? (
                              <Fragment>
                                <Button
                                  variant="warning"
                                  size="sm"
                                  type="submit"
                                >
                                  Actualizar Tarea
                                </Button>{" "}
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={(e) => {
                                    setUpdate(false);
                                    setWorkItem({
                                      projectOrderId: 0,
                                      workItemName: "",
                                      workItemDescription: "",
                                      time: 0,
                                      timeType: "",
                                    });
                                  }}
                                >
                                  Cancelar
                                </Button>
                              </Fragment>
                            ) : (
                              <Button variant="warning" type="submit" size="sm">
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>{" "}
                                Agregar Tarea
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                  <Row className="m-3">
                    <h4>Lista de tareas</h4>
                    <Table className="table-hover table-striped w-full">
                      <thead>
                        <tr>
                          <th>Nombre</th>
                          <th>Motivo</th>
                          <th>Tiempo</th>
                          <th>Unidad de Tiempo</th>
                          <th>Status</th>
                          <th>Analista</th>
                        </tr>
                      </thead>
                      <tbody>
                        {workItems.map((i) => (
                          <tr key={i.idWorkItemOrder}>
                            <td>{i.workItemName}</td>
                            <td>{i.reason}</td>
                            <td>{i.time}</td>
                            <td>{i.timeType}</td>
                            <td>
                              <Button
                                type="submit"
                                className={`btn  btn-sm btn-round`}
                                variant={
                                  i.status === "Asignado"
                                    ? "info"
                                    : i.status === "Iniciado"
                                    ? "success"
                                    : i.status === "Pendiente"
                                    ? "warning"
                                    : ""
                                }
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleStatus(i.idWorkItemOrder);
                                }}
                              >
                                {i.status}
                              </Button>
                            </td>
                            <td>
                              {users.filter((user) => user.idUser === i.userId)
                                .length > 0
                                ? users.filter(
                                    (user) => user.idUser === i.userId
                                  )[0].name
                                : "Sin Analista"}
                            </td>
                            <td className="text-right">
                              <Button
                                className="text-warning btn-link edit"
                                type="submit"
                                variant="warning"
                                size="sm"
                                onClick={(e) => handleEdit(i)}
                              >
                                <i className="fa fa-edit" />
                              </Button>
                              <Button
                                className="text-danger btn-link edit"
                                type="submit"
                                variant="danger"
                                size="sm"
                                onClick={(e) => handleDelete(i)}
                              >
                                <i className="fa fa-times" />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </div>
    </Fragment>
  );
};

export default Tasks;
