import React, { useEffect, useState, Fragment } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import clientAxios from "tools/axiosClient";

const Clients = () => {
  const MySwal = withReactContent(Swal);
  let navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [registers, setRegisters] = useState([]);
  const [loading, setLoading] = useState(true);
  const pagination = (isLoading) => {
    setLoading(isLoading);
    clientAxios()
      .get(`/v1/api/Clients`)
      .then(({ data }) => {
        if (data.error === false) {
          setClients(data.clients);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleDelete = (idClient) => {
    MySwal.fire({
      title: "¿Está seguro?",
      text: "No podrá revertir esto.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        clientAxios()
          .post(`/v1/api/Clients/Delete/${idClient}`)
          .then(({ data }) => {
            if (data.error === false) {
              pagination(false);
              Swal.fire({
                icon: "success",
                title:
                  "<h2>¡Eliminado!</h2><p>El cliente ha sido eliminado con éxito</p>",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          });
      }
    });
  };
  useEffect(() => {
    pagination(true);
  }, []);
  useEffect(() => {
    setRegisters(
      clients.map((prop, key) => {
        return {
          id: prop["idClient"],
          razonSocial: prop["razonSocial"],
          name: prop["clientName"],
          lastName: prop["clientLastName"],
          phone: prop["clientPhone"],
          email: prop["clientEmail"],
          address: prop["address"],
          actions: (
            <div className="actions-right">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="Edit_tooltip">Editar Cliente</Tooltip>}
              >
                <Button
                  onClick={() => {
                    navigate(`/admin/clients/${prop["idClient"]}`);
                  }}
                  variant="warning"
                  size="sm"
                  className="text-warning btn-link edit"
                >
                  <i className="fa fa-edit" />
                </Button>
              </OverlayTrigger>{" "}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="delete_tooltip">Eliminar Cliente</Tooltip>
                }
              >
                <Button
                  onClick={() => handleDelete(prop["idClient"])}
                  variant="danger"
                  size="sm"
                  className="btn-link remove text-danger"
                >
                  <i className="fa fa-times" />
                </Button>
              </OverlayTrigger>
            </div>
          ),
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients]);
  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card style={{}}>
              <Card.Header>
                <Row>
                  <Col>
                    <Card.Title>Clientes</Card.Title>
                    <p className="category">Se muestran todos los Clientes.</p>
                  </Col>
                  <Col className="text-right">
                    <Button
                      type="submit"
                      variant="success"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/admin/clients/new");
                      }}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> Nuevo
                      Cliente
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {loading === true ? (
                  <Row>
                    <Col className="text-center">
                      <Spinner animation="border" role="status"></Spinner>
                    </Col>
                  </Row>
                ) : (
                  <ReactTable
                    data={registers}
                    columns={[
                      {
                        Header: "Razón Social",
                        accessor: "razonSocial",
                      },
                      {
                        Header: "Nombre del contacto",
                        accessor: "name",
                      },
                      {
                        Header: "Apellido del contacto",
                        accessor: "lastName",
                      },
                      {
                        Header: "Celular",
                        accessor: "phone",
                      },
                      {
                        Header: "Correo Electronico",
                        accessor: "email",
                      },
                      {
                        Header: "Dirección",
                        accessor: "address",
                      },
                      {
                        Header: "Acciones",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    className="-striped -highlight primary-pagination animate__animated animate__fadeIn"
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Clients;
