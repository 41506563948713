import React, { useState, forwardRef, useImperativeHandle } from "react";
// react-bootstrap components
import {
  Button,
  Card,
  FormGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import { Formik, Form } from "formik";
import Select from "react-select";
import ClientAxios from "tools/axiosClient";

const Step3 = forwardRef((props, ref) => {
  const { form, setForm } = props;
  const [time, setTime] = useState(form.Time);
  const [timeError, setTimeError] = useState(null);
  const [timeTypeSelect, setTimeTypeSelect] = useState(form.TimeType);
  const [timeTypeError, setTimeTypeError] = useState(null);

  const next = () => {
    const timeTab = document.getElementById(
      "page-subcategories-tabs-example-tab-time-tab"
    );
    const timeContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-time-tab"
    );
    const percentTab = document.getElementById(
      "page-subcategories-tabs-example-tab-percent-tab"
    );
    const percentContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-percent-tab"
    );
    timeTab.classList.remove("active");
    timeContent.classList.remove("active", "show");
    percentTab.classList.add("active");
    percentContent.classList.add("active", "show");
  };

  const time_types = [
    { value: "dias", label: "Días" },
    { value: "horas", label: "Horas" },
  ];

  const back = () => {
    const costTab = document.getElementById(
      "page-subcategories-tabs-example-tab-general-cost-tab"
    );
    const costContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-general-cost-tab"
    );
    const timeTab = document.getElementById(
      "page-subcategories-tabs-example-tab-time-tab"
    );
    const timeContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-time-tab"
    );
    costTab.classList.add("active");
    costContent.classList.add("active", "show");
    timeTab.classList.remove("active");
    timeContent.classList.remove("active", "show");
  };

  return (
    <div className="wizard-step" ref={ref}>
      <Row>
        <Col className="ml-auto mr-auto" md="8">
          <Formik
            enableReinitialize={true}
            initialValues={form}
            onSubmit={next}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <Card.Body>
                  <Row className="justify-content-center">
                    <Col lg={4} md={4} sm={12} className="mr-4">
                      <FormGroup>
                        <label>Tiempo</label>
                        <FormControl
                          value={values.Time}
                          name="Time"
                          type="number"
                          disabled="true"
                        />
                        {timeError}
                      </FormGroup>
                    </Col>
                    <Col lg={5} md={5} sm={12}>
                      <FormGroup>
                        <label>Unidad de tiempo</label>
                        <Select
                          name="TimeType"
                          placeholder="Selecciona una unidad"
                          value={time_types.map((time) => {
                            if (time.value === values.TimeType) {
                              return { value: time.value, label: time.label };
                            } else return null;
                          })}
                        />
                        {timeTypeError}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-end mt-3">
                    <Col className="col-6">
                      <a className="btn btn-dark" onClick={() => back()}>
                        Volver
                      </a>
                    </Col>
                    <Col className="col-6">
                      <Button className="btn btn-info" type="submit">
                        Continuar
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
});

export default Step3;
