import React, { useState, useEffect, forwardRef } from "react";
// react-bootstrap components
import {
  Button,
  Card,
  FormGroup,
  FormControl,
  Row,
  Col,
  FormCheck,
} from "react-bootstrap";
import { Formik, Form } from "formik";
import clientAxios from "tools/axiosClient";
import Select from "react-select";

const Step1 = forwardRef((props, ref) => {
  const { form, setForm } = props;
  const [clients, setClients] = useState([]);
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);

  const next = () => {
    const infoTab = document.getElementById(
      "page-subcategories-tabs-example-tab-general-information-tab"
    );
    const infoContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-general-information-tab"
    );
    const costTab = document.getElementById(
      "page-subcategories-tabs-example-tab-general-cost-tab"
    );
    const costContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-general-cost-tab"
    );
    infoTab.classList.remove("active");
    infoContent.classList.remove("active", "show");
    costTab.classList.add("active");
    costContent.classList.add("active", "show");
  };

  useEffect(() => {
    clientAxios()
      .get(`/v1/api/Clients`)
      .then(({ data }) => {
        if (data.error === false) {
          setClients(data.clients);
        }
      });
    clientAxios()
      .get(`/v1/api/Users`)
      .then(({ data }) => {
        if (data.error === false) {
          setUsers(data.users);
        }
      });
    clientAxios()
      .get(`/v1/api/Projects`)
      .then(({ data }) => {
        if (data.error === false) {
          setProjects(data.projects);
        }
      });
  }, []);

  return (
    <div className="wizard-step" ref={ref}>
      <Row>
        <Col className="ml-auto mr-auto" md="10">
          <Formik
            enableReinitialize={true}
            initialValues={form}
            onSubmit={next}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <Card.Body>
                  <Row className="justify-content-center">
                    <Col lg={4} md={4} sm={12}>
                      <FormGroup>
                        <label>Cliente</label>
                        <Select
                          name="ClientId"
                          value={clients.map((p) => {
                            if (p.idClient === values.ClientId) {
                              return {
                                value: p.idClient,
                                label: p.clientName,
                              };
                            } else return null;
                          })}
                          placeholder="Selecciona un cliente"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={2} md={2} sm={12}>
                      <br></br>
                      <FormCheck className="mt-2">
                        <FormCheck.Label>
                          <FormCheck.Input
                            value={values.Iguala}
                            type="checkbox"
                            name="Iguala"
                            onChange={handleChange}
                            disabled="true"
                          />
                          <span className="form-check-sign"></span>
                          <label>Iguala</label>
                        </FormCheck.Label>
                      </FormCheck>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                      <FormGroup>
                        <label>Analista</label>
                        <Select
                          name="UserId"
                          value={users.map((p) => {
                            if (p.idUser === values.UserId) {
                              return {
                                value: p.idUser,
                                label: p.name,
                              };
                            } else {
                              return null;
                            }
                          })}
                          placeholder="Selecciona un analista"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col lg={4} md={4} sm={12} className="mr-4">
                      <FormGroup>
                        <label>Proyecto</label>
                        <Select
                          name="ProjectId"
                          value={projects.map((p) => {
                            if (p.idProject === values.ProjectId) {
                              return {
                                value: p.idProject,
                                label: p.projectName,
                              };
                            } else {
                              return null;
                            }
                          })}
                          placeholder="Selecciona un proyecto"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <label>Descripción</label>
                        <FormControl
                          value={values.ProjectByClientDescription}
                          name="ProjectByClientDescription"
                          type="text"
                          autoComplete="true"
                          disabled="true"
                        ></FormControl>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-end">
                    <Col className="col-2">
                      <Button className="btn btn-info mb-4 mt-4" type="submit">
                        Continuar
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
});

export default Step1;
