import React, { useEffect, useState, Fragment } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
// core components
import ReactTable from "components/ReactTable/ReactTable";
import clientAxios from "tools/axiosClient";

const Users = () => {
  let MySwal = withReactContent(Swal);
  let navigate = useNavigate();
  const [ users, setUsers ] = useState( [ ] );
  const [ registers, setRegisters ] = useState( [ ] );
  const [ loading, setLoading ] = useState( true );
  const pagination = ( isLoading ) => {
    setLoading(isLoading);
    clientAxios().get(`/v1/api/Users`).then( ( { data } ) => {
      if (data.error === false) {
        setUsers(data.users);
      }
    } ).finally( ( ) => {
      setLoading( false );
    } );
  }
  const handleDelete = ( id ) => {
    MySwal.fire({
      title: "¿Está seguro?",
      text: "No podrá revertir esto.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        clientAxios().post(`/v1/api/Users/Delete/${id}`).then(({ data }) => {
          if (data.error === false) {
            pagination(false);
            MySwal.fire({
              icon: "success",
              title: "<h2>¡Todo listo!</h2><p>Se ha eliminado el usuario</p>",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
      }
    });
  }
  useEffect(() => {
    pagination( true );
  }, [ ] );
  useEffect(() => {
    setRegisters(
      users.map( ( prop, key ) => {
        return {
          id: prop["idUser"],
          name: prop["name"],
          lastName: prop["lastName"],
          email: prop["email"],
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="edit_tooltip">Editar Usuario</Tooltip>}
              >
                <Button
                  onClick={() => {
                    navigate(`/admin/users/${prop["idUser"]}`);
                  }}
                  variant="warning"
                  size="sm"
                  className="text-warning btn-link edit"
                >
                  <i className="fa fa-edit" />
                </Button>
              </OverlayTrigger>{" "}
              {/* use this button to remove the data row */}
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id="delete_tooltip">Eliminar Usuario</Tooltip>
                }
              >
                <Button
                  onClick={ ( ) => handleDelete(prop["idUser"]) }
                  variant="danger"
                  size="sm"
                  className="btn-link remove text-danger"
                >
                  <i className="fa fa-times" />
                </Button>
              </OverlayTrigger>
            </div>
          ),
        };
      } )
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ users ] );
  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card style={{}}>
              <Card.Header className="animate__animated animate__fadeIn">
                <Row>
                  <Col>
                    <Card.Title>Usuarios</Card.Title>
                    <p className="category">Se muestran todos los usuarios.</p>
                  </Col>
                  <Col className="text-right">
                    <Button
                      type="submit"
                      variant="success"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/admin/users/new");
                      }}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i> Nuevo
                      Usuario
                    </Button>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {
                  loading === true ? (
                    <Row>
                      <Col className="text-center">
                        <Spinner animation="border" role="status"></Spinner>
                      </Col>
                    </Row>
                  ) : (
                    <ReactTable
                      data={registers}
                      columns={[
                        {
                          Header: "Nombre",
                          accessor: "name",
                        },
                        {
                          Header: "Apellidos",
                          accessor: "lastName",
                        },
                        {
                          Header: "Correo Electronico",
                          accessor: "email",
                        },
                        {
                          Header: "Acciones",
                          accessor: "actions",
                          sortable: false,
                          filterable: false,
                        },
                      ]}
                      className="-striped -highlight primary-pagination animate__animated animate__fadeIn "
                    />
                  )
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Users;
