import React, { useState, useEffect, Fragment } from "react";
import { useParams, useNavigate } from 'react-router-dom';
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  FormGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import clientAxios from "tools/axiosClient";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const NewPaymentType = ( ) => {
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const rol={
        //idUser:0,
        paymentName: '',
        paymentDescription: ''
    }
    const rolSchema = Yup.object().shape({
        paymentName: Yup.string().required('El Nombre es obligatorio'),
        paymentDescription: Yup.string().required('La Descripcion es obligatoria')
    });
    const handleSubmit=(values)=>{
        clientAxios().post(`/v1/api/PaymentTypes/Create`, values).then(({ data }) => {
            if(data.error===false){
                MySwal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                ).then(() => {
                    navigate('/admin/paymenttypes');
                });
            }
        });
    }
    return (
        <Fragment>
            <Container fluid>
                <Card className="stacked-form">
                    <Formik
                        enableReinitialize={ true }
                        initialValues={rol}
                        onSubmit={handleSubmit}
                        validationSchema={rolSchema}
                    >
                        {
                            ({values, errors, touched, handleChange})=>(
                                <Form>
                                    <Card.Header>
                                        <Card.Title as="h4">Crear Tipo de pago</Card.Title>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={6} md={6} sm={12}>
                                                <FormGroup>
                                                    <label>Nombre</label>
                                                    <FormControl
                                                        value={values.paymentName }
                                                        onChange={handleChange}
                                                        name="paymentName"
                                                        type="text"
                                                    ></FormControl>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={6} md={6} sm={12}>                                  
                                                <FormGroup>
                                                    <label>Descripcion</label>
                                                    <FormControl
                                                        value={values.paymentDescription }
                                                        name="paymentDescription"
                                                        onChange={handleChange}
                                                        type="text"
                                                    ></FormControl>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                    <Card.Footer>
                                      <Row>
                                          <Col>
                                              <Button className="btn-fill" type="submit" variant="secondary" onClick={(e)=>{e.preventDefault(); navigate("/admin/roles")}}>
                                                  Atras
                                              </Button>
                                          </Col>
                                          <Col className="text-right">
                                              <Button className="btn-fill" type="submit" variant="info">
                                                  Guardar
                                              </Button>
                                          </Col>
                                      </Row>
                                    </Card.Footer>
                                </Form>
                            )
                        }
                    </Formik>
                </Card>
            </Container>
    </Fragment>
  );
}

export default NewPaymentType;
