import React, { useState, useEffect, forwardRef } from "react";
// react-bootstrap components
import {
  Button,
  Card,
  FormGroup,
  FormControl,
  Row,
  Col,
  FormCheck,
} from "react-bootstrap";
import { Formik, Form } from "formik";
import clientAxios from "tools/axiosClient";
import Select from "react-select";

const Step2 = forwardRef((props, ref) => {
  const { form, setForm } = props;
  console.log(form);
  const [price, setPrice] = useState(form.Iguala === true ? 0 : form.Price);
  const [priceError, setPriceError] = useState(null);
  const [currencySelect, setCurrencySelect] = useState(null);
  const [currencyError, setCurrencyError] = useState(null);
  const [methodSelect, setMethodSelect] = useState(null);
  const [methodError, setMethodError] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [exhibitsSelect, setExhibitsSelect] = useState(null);
  const [exhibitsError, setexhibitsError] = useState(null);

  const nextTab = () => {
    const costTab = document.getElementById(
      "page-subcategories-tabs-example-tab-general-cost-tab"
    );
    const costContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-general-cost-tab"
    );
    const timeTab = document.getElementById(
      "page-subcategories-tabs-example-tab-time-tab"
    );
    const timeContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-time-tab"
    );
    costTab.classList.remove("active");
    costContent.classList.remove("active", "show");
    timeTab.classList.add("active");
    timeContent.classList.add("active", "show");
  };

  const isValidated = () => {
    price === ""
      ? setPriceError(
          <small className="text-danger">Debe asignar un precio.</small>
        )
      : setPriceError(null);
    currencySelect === null
      ? setCurrencyError(
          <small className="text-danger">Debe seleccionar una moneda.</small>
        )
      : setCurrencyError(null);
    methodSelect === null
      ? setMethodError(
          <small className="text-danger">Debe seleccionar un método.</small>
        )
      : setMethodError(null);
    exhibitsSelect === null
      ? setexhibitsError(
          <small className="text-danger">Debe seleccionar un método.</small>
        )
      : setexhibitsError(null);
    let precio = price !== "";
    let moneda = currencySelect !== null;
    let metodo = methodSelect !== null;
    let exhibit = exhibitsSelect !== null;
    let valid = precio && moneda && metodo && exhibit;
    const isIguala = form.Iguala;
    if (isIguala) {
      setPriceError(null);
      setCurrencyError(null);
      setMethodError(null);
      setexhibitsError(null);
      nextTab();
    } else if (valid) {
      nextTab();
    }
  };
  React.useImperativeHandle(ref, () => ({
    isValidated: () => {
      return isValidated();
    },
  }));

  const currencys = [
    { value: "MXN", label: "MXN" },
    { value: "USD", label: "USD" },
  ];

  const paidExhibits = [
    { value: "1", label: "1 pago" },
    { value: "2", label: "2 pagos" },
    { value: "3", label: "3 pagos" },
    { value: "4", label: "4 pagos" },
  ];

  const handleSubmit = (values) => {
    setForm({
      ...form,
      Price: parseFloat(values.Price),
      Currency: values.Currency,
      ExchangeRate: values.ExchangeRate,
      PaidExhibits: values.PaidExhibits,
      PaymentMethodId: values.PaymentMethodId,
      _1: values._1,
      _2: values._2,
      _3: values._3,
      _4: values._4,
    });
    isValidated();
  };

  const back = () => {
    const infoTab = document.getElementById(
      "page-subcategories-tabs-example-tab-general-information-tab"
    );
    const infoContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-general-information-tab"
    );
    const costTab = document.getElementById(
      "page-subcategories-tabs-example-tab-general-cost-tab"
    );
    const costContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-general-cost-tab"
    );
    infoTab.classList.add("active");
    infoContent.classList.add("active", "show");
    costTab.classList.remove("active");
    costContent.classList.remove("active", "show");
  };
  useEffect(() => {
    clientAxios()
      .get(`/v1/api/PaymentMethods`)
      .then(({ data }) => {
        if (data.error === false) {
          setPaymentMethods(data.paymentMethods);
        }
      });
  }, []);
  return (
    <div className="wizard-step" ref={ref}>
      <Row>
        <Col lg={12} md={12}>
          <Formik
            enableReinitialize={true}
            initialValues={form}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <Card.Body>
                  {values.Iguala ? (
                    <Row className="justify-content-center text-danger">
                      <h4>Cliente de Iguala, no es necesario asignar pago.</h4>
                    </Row>
                  ) : (
                    <></>
                  )}
                  <Row className="justify-content-start">
                    <Col lg={3} md={3} sm={12} className="mr-2">
                      <FormGroup>
                        <label>Precio</label>
                        <FormControl
                          value={form.Iguala === true ? 0: values.Price }
                          onChange={(e) => {
                            handleChange({
                              target: { value: e.target.value, name: "Price" },
                            });
                            setPrice(e.target);
                          }}
                          name="Price"
                          type="number"
                          autoComplete="true"
                          disabled={values.Iguala}
                        ></FormControl>
                        {priceError}
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                      <FormGroup>
                        <label>Moneda</label>
                        <Select       
                          readOnly
                          name="Currency"
                          placeholder="Selecciona una moneda"
                          value={currencys.map((currency) => {
                            if (currency.value === values.Currency) {
                              return {
                                value: currency.value,
                                label: currency.label,
                              };
                            } else return null;
                          })}
                          options={currencys}
                          onChange={(e) => {
                            handleChange({
                              target: { value: e.value, name: "Currency" },
                            });
                            setCurrencySelect(e.target);
                          }}
                          disabled={values.Iguala}
                        />
                        {currencyError}
                      </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={12}>
                      <FormGroup>
                        <label>Tipo de Cambio (opcional)</label>
                        <FormControl
                          disabled={form.Iguala === true ? "true" : "false" }
                          value={values.ExchangeRate}
                          onChange={handleChange}
                          name="ExchangeRate"
                          type="number"
                          disabled={values.Iguala}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-start">
                    <Col lg={2} md={2} sm={12} className="mr-4">
                      <br></br>
                      <FormCheck className="mt-2">
                        <FormCheck.Label>
                          <FormCheck.Input
                            disabled={form.Iguala === true ? "true" : "false" }
                            value={values.Invoice}
                            onChange={handleChange}
                            type="checkbox"
                            name="Invoice"
                            disabled={values.Iguala}
                          />
                          <span className="form-check-sign"></span>
                          <label>Factura</label>
                        </FormCheck.Label>
                      </FormCheck>
                    </Col>
                    <Col lg={5} md={5} sm={12}>
                      <FormGroup>
                        <label>Método de pago</label>
                        <Select
                          name="PaymentMethodId"
                          value={paymentMethods.map((method) => {
                            if (
                              method.idPaymentMethod === values.PaymentMethodId
                            ) {
                              return {
                                value: method.idPaymentMethod,
                                label: method.paymentMethodName,
                              };
                            } else return null;
                          })}
                          options={paymentMethods.map((p) => ({
                            value: p.idPaymentMethod,
                            label: p.paymentMethodName,
                          }))}
                          placeholder="Selecciona una método"
                          onChange={(e) => {
                            handleChange({
                              target: {
                                value: e.value,
                                name: "PaymentMethodId",
                              },
                            });
                            setMethodSelect(e.target);
                          }}
                          disabled={values.Iguala}
                        />
                        {methodError}
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                      <FormGroup>
                        <label>Exhibiciones de pago</label>
                        <Select
                          name="PaidExhibits"
                          placeholder="Selecciona los pagos"
                          value={paidExhibits.map((paidExhibit) => {
                            if (paidExhibit.value === values.PaidExhibits) {
                              return {
                                value: paidExhibit.value,
                                label: paidExhibit.label,
                              };
                            } else return null;
                          })}
                          options={paidExhibits}
                          onChange={(e) => {
                            handleChange({
                              target: { value: e.value, name: "PaidExhibits" },
                            });
                            setExhibitsSelect(e.target);
                          }}
                          disabled={values.Iguala}
                        />
                        {exhibitsError}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    {values.PaidExhibits != null &&
                      values.PaidExhibits !== "" &&
                      [...Array(parseInt(values.PaidExhibits))].map((x, i) => (
                        <Col lg={2} md={2} sm={2} className="mr-2">
                          <FormGroup>
                            <label>% {`${i + 1}`}</label>
                            <FormControl
                              value={values[`_${i + 1}`]}
                              onChange={handleChange}
                              name={`_${i + 1}`}
                              type="number"
                              autoComplete="true"
                            />
                          </FormGroup>
                        </Col>
                      ))}
                  </Row>
                  <Row className="justify-content-end mt-3">
                    <Col className="col-4">
                      <a
                        href="..."
                        className="btn btn-dark"
                        onClick={(e) => {
                          e.preventDefault();
                          back();
                        }}
                      >
                        Volver
                      </a>
                    </Col>
                    <Col className="col-5">
                      <Button type="submit" className="btn btn-info">
                        Continuar
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
});

export default Step2;
