import React, { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

//Tabs
import Step1 from "pages/Orders/Details/Step1.js";
import Step2 from "pages/Orders/Details/Step2.js";
import Step3 from "pages/Orders/Details/Step3.js";
import Step4 from "pages/Orders/Details/Step4.js";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// react-bootstrap components
import { Card, Nav, Container, Row, Col, Tab } from "react-bootstrap";

import clientAxios from "tools/axiosClient";
const MySwal = withReactContent(Swal);
const Details = () => {
  let navigate = useNavigate();
  let { id } = useParams();
  const [form, setForm] = useState({
    ClientId: "",
    ProjectId: "",
    UserId: "",
    Iguala: true,
    ProjectByClientDescription: "",
    Price: 0,
    Currency: "",
    ExchangeRate: null,
    PaymentMethodId: "",
    Time: 0,
    TimeType: "",
    Invoice: false,
    PaidExhibits: "",
  });
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    clientAxios()
      .get(`/v1/api/ProjectsOrders/${id}`)
      .then(({ data }) => {
        if (data.error === false) {
          let model = {
            IdOrder: data.projectOrder.idOrder,
            ClientId: data.projectOrder.clientId,
            ProjectId: data.projectOrder.projectId,
            UserId: data.projectOrder.userId,
            Iguala: data.projectOrder.iguala,
            ProjectByClientDescription:
              data.projectOrder.projectByClientDescription,
            Price: data.projectOrder.price,
            Currency: data.projectOrder.currency,
            ExchangeRate: data.projectOrder.exchangeRate,
            PaymentMethodId: data.projectOrder.paymentMethodId,
            Time: data.projectOrder.time,
            TimeType: data.projectOrder.timeType,
            Invoice: data.projectOrder.invoice,
            PaidExhibits: data.projectOrder.paidExhibits,
          };
          for (var i = 0; i < data.invoices.length; i++) {
            model[`_${i + 1}`] = data.invoices[i].percentage;
          }
          setForm(model);
        } else {
          MySwal.fire("Ups!, hubo un error", data.message, "error");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id]);
  return (
    <Fragment>
      <Container fluid>
        <Card>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              {loading === false && (
                <Tab.Container
                  id="page-subcategories-tabs-example"
                  defaultActiveKey="general-information-tab"
                  className="packages-container"
                >
                  <Nav
                    role="tablist"
                    variant="tabs"
                    className="justify-content-center packages-tab"
                  >
                    <Nav.Item className="package-tab-item pl-4 pr-4">
                      <Nav.Link
                        eventKey="general-information-tab"
                        className="border-0 bg-transparent package-tab-content"
                      >
                        <i className="mr-2"></i>
                        Información general
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="package-tab-item pl-4 pr-4">
                      <Nav.Link
                        eventKey="general-cost-tab"
                        className="border-0 bg-transparent package-tab-content"
                      >
                        <i className="mr-2"></i>
                        Tarifa general
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="package-tab-item pl-4 pr-4">
                      <Nav.Link
                        eventKey="time-tab"
                        className="border-0 bg-transparent package-tab-content"
                      >
                        <i className="mr-2"></i>
                        Tiempo
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="package-tab-item pl-4 pr-4">
                      <Nav.Link
                        eventKey="percent-tab"
                        className="border-0 bg-transparent package-tab-content"
                      >
                        <i className="mr-2"></i>
                        Tareas
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="general-information-tab">
                      <Step1 form={form} setForm={setForm} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="general-cost-tab">
                      <Step2 form={form} setForm={setForm} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="time-tab">
                      <Step3 form={form} setForm={setForm} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="percent-tab">
                      <Step4 form={form} setForm={setForm} />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              )}
            </Col>
          </Row>
          <Row className="justify-content-start">
            <Col className="col-2">
              <a
                href="..."
                className="btn btn-danger m-4"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/admin/orders");
                }}
              >
                ◄ Cancelar
              </a>
            </Col>
          </Row>
        </Card>
      </Container>
    </Fragment>
  );
};

export default Details;
