import React, { useState, useEffect, Fragment } from "react";
import { useParams, useNavigate } from "react-router-dom";
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  FormGroup,
  FormControl,
  Row,
  Col,
} from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import clientAxios from "tools/axiosClient";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const NewPosition = () => {
  let { id } = useParams();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();
  const [position, setPosition] = useState({
    positionName: "",
    positionDescription: "",
  });
  const Schema = Yup.object().shape({
    positionName: Yup.string().required("El Nombre es obligatorio"),
  });
  const handleSubmit = (values) => {
    clientAxios()
      .post(`/v1/api/Positions/Create`, values)
      .then(({ data }) => {
        if (data.error === false) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "<h2>¡Creado!</h2><p>El puesto ha sido creado con éxito</p>",
            showConfirmButton: false,
            timer: 1500,
          });
          navigate("/admin/positions");
        }
      });
  };
  return (
    <Fragment>
      <Container fluid>
        <Card className="stacked-form">
          <Formik
            enableReinitialize={true}
            initialValues={position}
            onSubmit={handleSubmit}
            validationSchema={Schema}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <Card.Header>
                  <Card.Title as="h4">Crear puesto</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Row>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <label>Nombre</label>
                        <FormControl
                          value={values.positionName}
                          onChange={handleChange}
                          name="positionName"
                          type="text"
                        ></FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={12}>
                      <FormGroup>
                        <label>Descripción</label>
                        <FormControl
                          value={values.positionDescription}
                          name="positionDescription"
                          onChange={handleChange}
                          type="text"
                        ></FormControl>
                      </FormGroup>
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col>
                      <Button
                        className="btn-fill"
                        type="submit"
                        variant="secondary"
                        onClick={(e) => {
                          e.preventDefault();
                          navigate("/admin/positions");
                        }}
                      >
                        Atras
                      </Button>
                    </Col>
                    <Col className="text-right">
                      <Button className="btn-fill" type="submit" variant="info">
                        Guardar
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              </Form>
            )}
          </Formik>
        </Card>
      </Container>
    </Fragment>
  );
};

export default NewPosition;
