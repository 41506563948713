import React, { useEffect, useState } from "react";
// react component used to create charts
import ChartistGraph from "react-chartist";
// react components used to create a SVG / Vector map
import { VectorMap } from "react-jvectormap";
//Swal
import Swal from "sweetalert2";

//Material Icons
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  OverlayTrigger,
  Table,
  Tooltip,
  Container,
  Row,
  Col,
  Spinner
} from "react-bootstrap";

import clientAxios from "tools/axiosClient";

function AdminDashboard() {  
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState({
    openProjects    : 0,
    revenueProjects : 0,
    revenuePaid     : 0,
    revenuePending  : 0,
    openAtMonth     : 0,
    closedAtMonth   : 0
});

  const loadInfo = () => {
    setLoading(true);
    clientAxios()
      .get(`/v1/api/AdminDashboard`)
      .then(({ data }) => {
        if (data.error === false) {          
          setInfo({
            openProjects    : data.view.openProjects,
            revenueProjects : data.view.revenueProjects,
            revenuePaid     : data.view.revenuePaid,
            revenuePending  : data.view.revenuePending,
            openAtMonth     : data.view.openAtMonth,
            closedAtMonth   : data.view.closedAtMonth  
        });
          console.log(data);
        }else{
          Swal.fire({
            position: "center",
            icon: "warning",
            title: 'Oops! Hubo un error al cargar el Dashboard',
            text: data.message,
            showConfirmButton: true,             
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    loadInfo();
  }, []);

  return (
    <>
      <Container fluid>
      {loading === true ? (
            <Row>
              <Col className="text-center">
                <Spinner animation="border" role="status"></Spinner>
              </Col>
            </Row>
          ) : (
            <>
            <Row>
              <Col lg="2" sm="4">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="4">
                        <div className="text-center">
                        <AccountTreeOutlinedIcon fontSize="large" sx={{ color: '#4866F4' }}/>
                        </div>
                      </Col>
                      <Col xs="8">
                        <div className="numbers">                          
                          <Card.Title as="h3">{info.openProjects}</Card.Title>                            
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-center">
                    <hr></hr>                    
                    <p className="card-category">Proyectos en curso</p>                   
                  </Card.Footer>
                </Card>
              </Col>
              <Col lg="2" sm="4">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="3">
                        <div className="text-center">
                          <PriceChangeOutlinedIcon fontSize="large" sx={{ color: '#6EBC52' }}/>
                        </div>
                      </Col>
                      <Col xs="9">
                        <div className="numbers mt-1">                          
                          <Card.Title as="h4">{info.revenueProjects.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-center">
                    <hr></hr>
                    <p className="card-category">Ganancias en curso</p>
                  </Card.Footer>
                </Card>
              </Col>
              <Col lg="2" sm="4">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="3">
                        <div className="text-center">
                          <PriceCheckOutlinedIcon fontSize="large" sx={{ color: '#6EBC52' }}/>
                        </div>
                      </Col>
                      <Col xs="9">
                        <div className="numbers mt-1">                          
                          <Card.Title as="h4">{info.revenuePaid.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-center">
                    <hr></hr>
                    <p className="card-category">Ganancias pagadas</p>
                  </Card.Footer>
                </Card>
              </Col>
              <Col lg="2" sm="4">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="3">
                        <div className="text-center">
                        <RequestQuoteOutlinedIcon fontSize="large" sx={{ color: '#ECC132' }}/>
                        </div>
                      </Col>
                      <Col xs="9">
                        <div className="numbers">                          
                          <Card.Title as="h4">{info.revenuePending.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-center">
                    <hr></hr>
                    <p className="card-category">Saldo pendiente</p>
                  </Card.Footer>
                </Card>
              </Col>
              <Col lg="2" sm="4">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="12" sm="3">
                        <div className="text-center">
                        <PlaylistAddOutlinedIcon fontSize="large" sx={{ color: '#4866F4' }}/>
                        </div>
                      </Col>
                      <Col xs="12" sm="9">
                        <div className="numbers">                          
                          <Card.Title as="h4">{info.openAtMonth}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="text-center">
                    <hr></hr>
                    <p className="card-category">Abiertos este mes</p>
                  </Card.Footer>
                </Card>
              </Col>
              <Col lg="2" sm="4">
                <Card className="card-stats">
                  <Card.Body>
                    <Row>
                      <Col xs="3">
                        <div className="text-center">
                        <PlaylistAddCheckOutlinedIcon fontSize="large" sx={{ color: '#6EBC52' }}/>
                        </div>
                      </Col>
                      <Col xs="9">
                        <div className="numbers">                          
                          <Card.Title as="h4">{info.closedAtMonth}</Card.Title>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer>
                    <hr></hr>
                    <p className="card-category">Cerrados este mes</p>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Proyectos Pendientes Por Analista</Card.Title>
                    <p className="card-category">Se cuentan los proyectos con estatus diferente a 'Rechazado' y 'Cerrado'.</p>
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col md="6">
                        <Table responsive>
                          <tbody>
                            <tr>
                              <td>
                                <div className="flag">
                                  <img
                                    alt="..."
                                    src={require("assets/img/flags/US.png")}
                                  ></img>
                                </div>
                              </td>
                              <td>USA</td>
                              <td className="text-right">2.920</td>
                              <td className="text-right">53.23%</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="flag">
                                  <img
                                    alt="..."
                                    src={require("assets/img/flags/DE.png")}
                                  ></img>
                                </div>
                              </td>
                              <td>Germany</td>
                              <td className="text-right">1.300</td>
                              <td className="text-right">20.43%</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="flag">
                                  <img
                                    alt="..."
                                    src={require("assets/img/flags/AU.png")}
                                  ></img>
                                </div>
                              </td>
                              <td>Australia</td>
                              <td className="text-right">760</td>
                              <td className="text-right">10.35%</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="flag">
                                  <img
                                    alt="..."
                                    src={require("assets/img/flags/GB.png")}
                                  ></img>
                                </div>
                              </td>
                              <td>United Kingdom</td>
                              <td className="text-right">690</td>
                              <td className="text-right">7.87%</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="flag">
                                  <img
                                    alt="..."
                                    src={require("assets/img/flags/RO.png")}
                                  ></img>
                                </div>
                              </td>
                              <td>Romania</td>
                              <td className="text-right">600</td>
                              <td className="text-right">5.94%</td>
                            </tr>
                            <tr>
                              <td>
                                <div className="flag">
                                  <img
                                    alt="..."
                                    src={require("assets/img/flags/BR.png")}
                                  ></img>
                                </div>
                              </td>
                              <td>Brasil</td>
                              <td className="text-right">550</td>
                              <td className="text-right">4.34%</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                      <Col className="ml-auto mr-auto" md="6">
                        <VectorMap
                          map={"world_mill"}
                          backgroundColor="transparent"
                          zoomOnScroll={false}
                          containerStyle={{
                            width: "100%",
                            height: "300px"
                          }}
                          containerClassName="map"
                          regionStyle={{
                            initial: {
                              fill: "#e4e4e4",
                              "fill-opacity": 0.9,
                              stroke: "none",
                              "stroke-width": 0,
                              "stroke-opacity": 0
                            }
                          }}
                          series={{
                            regions: [
                              {
                                values: {
                                  AU: 760,
                                  BR: 550,
                                  CA: 120,
                                  DE: 1300,
                                  FR: 540,
                                  GB: 690,
                                  GE: 200,
                                  IN: 200,
                                  RO: 600,
                                  RU: 300,
                                  US: 2920
                                },
                                scale: ["#AAAAAA", "#444444"],
                                normalizeFunction: "polynomial"
                              }
                            ]
                          }}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Email Statistics</Card.Title>
                    <p className="card-category">Last Campaign Performance</p>
                  </Card.Header>
                  <Card.Body>
                    <ChartistGraph
                      className="ct-perfect-fourth"
                      data={{
                        labels: ["40%", "20%", "40%"],
                        series: [40, 20, 40]
                      }}
                      type="Pie"
                    />
                  </Card.Body>
                  <Card.Footer>
                    <div className="legend">
                      <i className="fas fa-circle mr-1 text-info"></i>
                      Open <i className="fas fa-circle mr-1 text-danger"></i>
                      Bounce <i className="fas fa-circle mr-1 text-warning"></i>
                      Unsubscribe
                    </div>
                    <hr></hr>
                    <div className="stats">
                      <i className="far fa-clock-o"></i>
                      Campaign sent 2 days ago
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
              <Col md="8">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">Users Behavior</Card.Title>
                    <p className="card-category">24 Hours performance</p>
                  </Card.Header>
                  <Card.Body>
                    <ChartistGraph
                      data={{
                        labels: [
                          "9:00AM",
                          "12:00AM",
                          "3:00PM",
                          "6:00PM",
                          "9:00PM",
                          "12:00PM",
                          "3:00AM",
                          "6:00AM",
                          ""
                        ],
                        series: [
                          [287, 385, 490, 492, 554, 586, 698, 695, 630],
                          [67, 152, 143, 240, 287, 335, 435, 437, 470],
                          [23, 113, 67, 108, 190, 239, 307, 308, 430]
                        ]
                      }}
                      type="Line"
                      options={{
                        low: 0,
                        high: 800,
                        showArea: false,
                        height: "245px",
                        axisX: {
                          showGrid: false
                        },
                        lineSmooth: true,
                        showLine: true,
                        showPoint: true,
                        fullWidth: true,
                        chartPadding: {
                          right: 50
                        }
                      }}
                      responsiveOptions={[
                        [
                          "screen and (max-width: 640px)",
                          {
                            axisX: {
                              labelInterpolationFnc: function (value) {
                                return value[0];
                              }
                            }
                          }
                        ]
                      ]}
                    />
                  </Card.Body>
                  <Card.Footer>
                    <div className="legend">
                      <i className="fas fa-circle mr-1 text-info"></i>
                      Open <i className="fas fa-circle mr-1 text-danger"></i>
                      Click <i className="fas fa-circle mr-1 text-warning"></i>
                      Click Second Time
                    </div>
                    <hr></hr>
                    <div className="stats">
                      <i className="fas fa-history"></i>
                      Updated 3 minutes ago
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">2017 Sales</Card.Title>
                    <p className="card-category">All products including Taxes</p>
                  </Card.Header>
                  <Card.Body>
                    <ChartistGraph
                      data={{
                        labels: [
                          "Jan",
                          "Feb",
                          "Mar",
                          "Apr",
                          "Mai",
                          "Jun",
                          "Jul",
                          "Aug",
                          "Sep",
                          "Oct",
                          "Nov",
                          "Dec"
                        ],
                        series: [
                          [
                            542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756,
                            895
                          ],
                          [
                            412, 243, 280, 580, 453, 353, 300, 364, 368, 410, 636,
                            695
                          ]
                        ]
                      }}
                      type="Bar"
                      options={{
                        seriesBarDistance: 10,
                        axisX: {
                          showGrid: false
                        },
                        height: "245px"
                      }}
                      responsiveOptions={[
                        [
                          "screen and (max-width: 640px)",
                          {
                            seriesBarDistance: 5,
                            axisX: {
                              labelInterpolationFnc: function (value) {
                                return value[0];
                              }
                            }
                          }
                        ]
                      ]}
                    />
                  </Card.Body>
                  <Card.Footer>
                    <div className="legend">
                      <i className="fas fa-circle mr-1 text-info"></i>
                      Tesla Model S{" "}
                      <i className="fas fa-circle mr-1 text-danger"></i>
                      BMW 5 Series
                    </div>
                    <hr></hr>
                    <div className="stats">
                      <i className="fas fa-check"></i>
                      Data information certified
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-tasks">
                  <Card.Header>
                    <Card.Title as="h4">Tasks</Card.Title>
                    <p className="card-category">Backend development</p>
                  </Card.Header>
                  <Card.Body>
                    <div className="table-full-width">
                      <Table>
                        <tbody>
                          <tr>
                            <td>
                              <Form.Check className="mb-1 pl-0">
                                <Form.Check.Label>
                                  <Form.Check.Input
                                    defaultValue=""
                                    type="checkbox"
                                  ></Form.Check.Input>
                                  <span className="form-check-sign"></span>
                                </Form.Check.Label>
                              </Form.Check>
                            </td>
                            <td>
                              Sign contract for "What are conference organizers
                              afraid of?"
                            </td>
                            <td className="td-actions text-right">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-688296980">
                                    Edit Task..
                                  </Tooltip>
                                }
                                placement="top"
                              >
                                <Button
                                  className="btn-simple btn-link"
                                  type="button"
                                  variant="info"
                                >
                                  <i className="fas fa-edit"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-202192706">Remove..</Tooltip>
                                }
                                placement="top"
                              >
                                <Button
                                  className="btn-simple btn-link"
                                  type="button"
                                  variant="danger"
                                >
                                  <i className="fas fa-times"></i>
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Form.Check className="mb-1 pl-0">
                                <Form.Check.Label>
                                  <Form.Check.Input
                                    defaultChecked
                                    defaultValue=""
                                    type="checkbox"
                                  ></Form.Check.Input>
                                  <span className="form-check-sign"></span>
                                </Form.Check.Label>
                              </Form.Check>
                            </td>
                            <td>
                              Lines From Great Russian Literature? Or E-mails From
                              My Boss?
                            </td>
                            <td className="td-actions text-right">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-746544352">
                                    Edit Task..
                                  </Tooltip>
                                }
                                placement="top"
                              >
                                <Button
                                  className="btn-simple btn-link"
                                  type="button"
                                  variant="info"
                                >
                                  <i className="fas fa-edit"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-743037005">Remove..</Tooltip>
                                }
                                placement="top"
                              >
                                <Button
                                  className="btn-simple btn-link"
                                  type="button"
                                  variant="danger"
                                >
                                  <i className="fas fa-times"></i>
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Form.Check className="mb-1 pl-0">
                                <Form.Check.Label>
                                  <Form.Check.Input
                                    defaultChecked
                                    defaultValue=""
                                    type="checkbox"
                                  ></Form.Check.Input>
                                  <span className="form-check-sign"></span>
                                </Form.Check.Label>
                              </Form.Check>
                            </td>
                            <td>
                              Flooded: One year later, assessing what was lost and
                              what was found when a ravaging rain swept through
                              metro Detroit
                            </td>
                            <td className="td-actions text-right">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-855684210">
                                    Edit Task..
                                  </Tooltip>
                                }
                                placement="top"
                              >
                                <Button
                                  className="btn-simple btn-link"
                                  type="button"
                                  variant="info"
                                >
                                  <i className="fas fa-edit"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-242945902">Remove..</Tooltip>
                                }
                                placement="top"
                              >
                                <Button
                                  className="btn-simple btn-link"
                                  type="button"
                                  variant="danger"
                                >
                                  <i className="fas fa-times"></i>
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Form.Check className="mb-1 pl-0">
                                <Form.Check.Label>
                                  <Form.Check.Input
                                    defaultChecked
                                    type="checkbox"
                                  ></Form.Check.Input>
                                  <span className="form-check-sign"></span>
                                </Form.Check.Label>
                              </Form.Check>
                            </td>
                            <td>
                              Create 4 Invisible User Experiences you Never Knew
                              About
                            </td>
                            <td className="td-actions text-right">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-488557184">
                                    Edit Task..
                                  </Tooltip>
                                }
                                placement="top"
                              >
                                <Button
                                  className="btn-simple btn-link"
                                  type="button"
                                  variant="info"
                                >
                                  <i className="fas fa-edit"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-789597281">Remove..</Tooltip>
                                }
                                placement="top"
                              >
                                <Button
                                  className="btn-simple btn-link"
                                  type="button"
                                  variant="danger"
                                >
                                  <i className="fas fa-times"></i>
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Form.Check className="mb-1 pl-0">
                                <Form.Check.Label>
                                  <Form.Check.Input
                                    defaultValue=""
                                    type="checkbox"
                                  ></Form.Check.Input>
                                  <span className="form-check-sign"></span>
                                </Form.Check.Label>
                              </Form.Check>
                            </td>
                            <td>Read "Following makes Medium better"</td>
                            <td className="td-actions text-right">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-521344137">
                                    Edit Task..
                                  </Tooltip>
                                }
                                placement="top"
                              >
                                <Button
                                  className="btn-simple btn-link"
                                  type="button"
                                  variant="info"
                                >
                                  <i className="fas fa-edit"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-934093947">Remove..</Tooltip>
                                }
                                placement="top"
                              >
                                <Button
                                  className="btn-simple btn-link"
                                  type="button"
                                  variant="danger"
                                >
                                  <i className="fas fa-times"></i>
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Form.Check className="mb-1 pl-0">
                                <Form.Check.Label>
                                  <Form.Check.Input
                                    defaultChecked
                                    defaultValue=""
                                    type="checkbox"
                                  ></Form.Check.Input>
                                  <span className="form-check-sign"></span>
                                </Form.Check.Label>
                              </Form.Check>
                            </td>
                            <td>Unfollow 5 enemies from twitter</td>
                            <td className="td-actions text-right">
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-97404283">
                                    Edit Task..
                                  </Tooltip>
                                }
                                placement="top"
                              >
                                <Button
                                  className="btn-simple btn-link"
                                  type="button"
                                  variant="info"
                                >
                                  <i className="fas fa-edit"></i>
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-888894273">Remove..</Tooltip>
                                }
                                placement="top"
                              >
                                <Button
                                  className="btn-simple btn-link"
                                  type="button"
                                  variant="danger"
                                >
                                  <i className="fas fa-times"></i>
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                  <Card.Footer>
                    <hr></hr>
                    <div className="stats">
                      <i className="now-ui-icons loader_refresh spin"></i>
                      Updated 3 minutes ago
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
            </>
                )}
      </Container>
    </>
  );
}

export default AdminDashboard;
