import React, { useEffect, useState, Fragment } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from "react-router-dom";
import { authStore } from "store/authStore.js";
// cambio pal cholo alv y gael <3
// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import clientAxios from "tools/axiosClient";

const Orders = () => {
  const MySwal = withReactContent(Swal);
  let navigate = useNavigate();
  const { user } = authStore();
  const [orders, setOrders] = useState([]);
  const [clients, setClients] = useState([{ ClientName: "" }]);
  const [users, setUsers] = useState([{ user: "" }]);
  const [registers, setRegisters] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleStatus = (id) => {
    MySwal.fire({
      title: "cambiar estatus",
      input: "select",
      inputOptions: {
        Asignado: "Asignado",
        Iniciado: "Iniciado",
        EnEspera: "En espera",
        EnRevision: "En revisión",
        Entregado: "Entregado",
        Rechazado: "Rechazado",
        Cerrado: "Cerrado",
      },
      inputPlaceholder: "Selecciona un estatus",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value !== "") {
            resolve();
          } else {
            resolve("Seleccione un estatus");
          }
        });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        clientAxios()
          .post(`/v1/api/ProjectsOrders/UpdateStatus`, {
            id: id,
            status: result.value,
          })
          .then(({ data }) => {
            if (data.error === false) {
              pagination(false);
              Swal.fire({
                position: "top-end",
                icon: "success",
                title: "El estatus ha sido actualizado",
                showConfirmButton: false,
                timer: 1500,
              });
            }else{
              pagination(false);
              Swal.fire({
                position: "center",
                icon: "warning",
                title: data.message,
                showConfirmButton: true,                
              });
            }
          });
      }
    });
  };
  const pagination = (isLoading) => {
    setLoading(isLoading);
    clientAxios()
      .get(`/v1/api/ProjectsOrders`)
      .then(({ data }) => {
        if (data.error === false) {
          setOrders(data.projectsOrders);
          setClients(data.clients);
          setUsers(data.users);
          console.log("USUARIOS: ");
          console.log(data.users);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    pagination(true);
  }, []);

  useEffect(() => {
    setRegisters(
      orders.map((prop, key) => {
        const client = clients.filter(
          (client) => client.idClient === prop["clientId"]
        );
        const userName = users.filter(
          (user) => user.idUser === prop["userId"]
        );
        console.log("ANALISTA");
        console.log(userName.name);
        let status = "";
        if (prop["statusOrder"] === "Asignado") {
          status = "info";
        }
        if (prop["statusOrder"] === "Iniciado") {
          status = "success";
        }
        return {
          id: prop["idOrder"],
          client: client.length > 0 && client[0].clientName,
          description: prop["projectByClientDescription"],
          iguala: prop["iguala"],
          status: (
            <Button
              type="submit"
              className={`btn  btn-sm btn-round`}
              variant={status}
              onClick={(e) => {
                e.preventDefault();
                handleStatus(prop["idOrder"]);
              }}
            >
              {prop["statusOrder"]}
            </Button>
          ),
          //price: prop["price"],
          time: prop["time"],
          time_type: prop["timeType"],
          user: userName[0].name,
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="Edit_tooltip">Tareas</Tooltip>}
              >
                <Button
                  onClick={() => {
                    navigate(`/admin/orders/tasks/${prop["idOrder"]}`);
                  }}
                  variant="success"
                  size="sm"
                  className="text-success btn-link"
                >
                  <i className="fa fa-tasks" />
                </Button>
              </OverlayTrigger>
              {user.idRol !== 11 && (
                <Fragment>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="Edit_tooltip">Detalles</Tooltip>}
                  >
                    <Button
                      onClick={() => {
                        navigate(`/admin/orders/details/${prop["idOrder"]}`);
                      }}
                      variant="info"
                      size="sm"
                      className="text-info btn-link"
                    >
                      <i className="fa fa-list" />
                    </Button>
                          </OverlayTrigger>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="Edit_tooltip">Editar</Tooltip>}
                  >
                    <Button
                      onClick={() => {
                        navigate(`/admin/orders/edit/${prop["idOrder"]}`);
                      }}
                      variant="warning"
                      size="sm"
                      className="text-warning btn-link edit"
                    >
                      <i className="fa fa-edit" />
                    </Button>
                          </OverlayTrigger>

                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="Edit_tooltip">Eliminar</Tooltip>}
                  >
                    <Button
                      onClick={() => {
                        MySwal.fire({
                          title:
                            "¿Seguro que desea eliminar el proyecto y sus tareas?",
                          text: "¡No podrá revertir esto!",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Sí, eliminar.",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            clientAxios()
                              .post(
                                `/v1/api/ProjectsOrders/delete/${prop["idOrder"]}`
                              )
                              .then(({ data }) => {
                                if (data.error === false) {
                                  pagination(false);
                                  MySwal.fire(
                                    "¡Eliminado!",
                                    "El proyecto y sus tareas han sido eliminados.",
                                    "success"
                                  );
                                }
                              });
                          }
                        });
                      }}
                      variant="danger"
                      size="sm"
                      className="btn-link remove text-danger"
                    >
                      <i className="fa fa-times" />
                    </Button>
                  </OverlayTrigger>
                </Fragment>
              )}
            </div>
          ),
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);
  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card style={{}}>
              <Card.Header>
                <Row>
                  <Col>
                    <Card.Title>Proyectos</Card.Title>
                    <p className="category">
                      Se muestran todos los proyectos en seguimiento.
                    </p>
                  </Col>
                  <Col className="text-right">
                    { user.idRol !== 11 && 
                    (
                      <Button
                      type="submit"
                      variant="success"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/admin/Orders/new");
                      }}
                      >
                        <i className="fa fa-plus" aria-hidden="true"></i> Nuevo
                        Proyecto
                      </Button>
                    )}                 
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body>
                {loading === true ? (
                  <Row>
                    <Col className="text-center">
                      <Spinner animation="border" role="status"></Spinner>
                    </Col>
                  </Row>
                ) : (
                  <ReactTable
                    data={registers}
                    columns={[
                      {
                        Header: "ID",
                        accessor: "id",
                      },
                      {
                        Header: "Cliente",
                        accessor: "client",
                      },
                      {
                        Header: "Nombre",
                        accessor: "description",
                      },
                      // {
                      //   Header: "Precio",
                      //   accessor: "price",
                      // },
                      {
                        Header: "Tiempo",
                        accessor: "time",
                      },
                      {
                        Header: "Unidad Tiempo",
                        accessor: "time_type",
                      },
                      {
                        Header: "Status",
                        accessor: "status",
                      },
                      {
                        Header: "Analista",
                        accessor: "user",
                      },
                      {
                        Header: "Acciones",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    className="-striped -highlight primary-pagination animate__animated animate__fadeIn"
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Orders;
