import React, { useState, useEffect, forwardRef } from "react";
// react-bootstrap components
import {
  Button,
  Card,
  FormGroup,
  FormControl,
  Row,
  Col,
  FormCheck,
} from "react-bootstrap";
import { Formik, Form } from "formik";
import clientAxios from "tools/axiosClient";
import Select from "react-select";

const Step2 = forwardRef((props, ref) => {
  const { form, setForm } = props;
  const [paymentMethods, setPaymentMethods] = useState([]);

  const next = () => {
    const costTab = document.getElementById(
      "page-subcategories-tabs-example-tab-general-cost-tab"
    );
    const costContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-general-cost-tab"
    );
    const timeTab = document.getElementById(
      "page-subcategories-tabs-example-tab-time-tab"
    );
    const timeContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-time-tab"
    );
    costTab.classList.remove("active");
    costContent.classList.remove("active", "show");
    timeTab.classList.add("active");
    timeContent.classList.add("active", "show");
  };

  const currencys = [
    { value: "MXN", label: "MXN" },
    { value: "USD", label: "USD" },
  ];

  const paidExhibits = [
    { value: "1", label: "1 pago" },
    { value: "2", label: "2 pagos" },
    { value: "3", label: "3 pagos" },
    { value: "4", label: "4 pagos" },
  ];

  const back = () => {
    const infoTab = document.getElementById(
      "page-subcategories-tabs-example-tab-general-information-tab"
    );
    const infoContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-general-information-tab"
    );
    const costTab = document.getElementById(
      "page-subcategories-tabs-example-tab-general-cost-tab"
    );
    const costContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-general-cost-tab"
    );
    infoTab.classList.add("active");
    infoContent.classList.add("active", "show");
    costTab.classList.remove("active");
    costContent.classList.remove("active", "show");
  };
  useEffect(() => {
    clientAxios()
      .get(`/v1/api/PaymentMethods`)
      .then(({ data }) => {
        if (data.error === false) {
          setPaymentMethods(data.paymentMethods);
        }
      });
  }, []);
  return (
    <div className="wizard-step" ref={ref}>
      <Row>
        <Col lg={12} md={12}>
          <Formik
            enableReinitialize={true}
            initialValues={form}
            onSubmit={next}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form>
                <Card.Body>
                  <Row className="justify-content-start">
                    <Col lg={3} md={3} sm={12} className="mr-2">
                      <FormGroup>
                        <label>Precio</label>
                        <FormControl
                          value={values.Price}
                          name="Price"
                          type="number"
                          autoComplete="true"
                          disabled="true"
                        ></FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                      <FormGroup>
                        <label>Moneda</label>
                        <Select
                          name="Currency"
                          placeholder="Selecciona una moneda"
                          value={currencys.map((currency) => {
                            if (currency.value === values.Currency) {
                              return {
                                value: currency.value,
                                label: currency.label,
                              };
                            } else return null;
                          })}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={12}>
                      <FormGroup>
                        <label>Tipo de Cambio (opcional)</label>
                        <FormControl
                          value={values.ExchangeRate}
                          name="ExchangeRate"
                          type="number"
                          disabled="true"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-start">
                    <Col lg={2} md={2} sm={12} className="mr-4">
                      <br></br>
                      <FormCheck className="mt-2">
                        <FormCheck.Label>
                          <FormCheck.Input
                            value={values.Invoice}
                            type="checkbox"
                            name="Invoice"
                            disabled="true"
                          />
                          <span className="form-check-sign"></span>
                          <label>Factura</label>
                        </FormCheck.Label>
                      </FormCheck>
                    </Col>
                    <Col lg={5} md={5} sm={12}>
                      <FormGroup>
                        <label>Método de pago</label>
                        <Select
                          name="PaymentMethodId"
                          value={paymentMethods.map((method) => {
                            if (
                              method.idPaymentMethod === values.PaymentMethodId
                            ) {
                              return {
                                value: method.idPaymentMethod,
                                label: method.paymentMethodName,
                              };
                            } else return null;
                          })}
                          placeholder="Selecciona una método"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={4} md={4} sm={12}>
                      <FormGroup>
                        <label>Exhibiciones de pago</label>
                        <Select
                          name="PaidExhibits"
                          placeholder="Selecciona los pagos"
                          value={paidExhibits.map((paidExhibit) => {
                            if (
                              paidExhibit.value ===
                              values.PaidExhibits.toString()
                            ) {
                              return {
                                value: paidExhibit.value,
                                label: paidExhibit.label,
                              };
                            } else return null;
                          })}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-center">
                    {values.PaidExhibits != null &&
                      values.PaidExhibits !== "" &&
                      [...Array(parseInt(values.PaidExhibits))].map((x, i) => (
                        <Col
                          lg={2}
                          md={2}
                          sm={2}
                          className="mr-2"
                          key={`paidExit${i}`}
                        >
                          <FormGroup>
                            <label>% {`${i + 1}`}</label>
                            <FormControl
                              value={values[`_${i + 1}`]}
                              name={`_${i + 1}`}
                              type="number"
                              autoComplete="true"
                              disabled="true"
                            />
                          </FormGroup>
                        </Col>
                      ))}
                  </Row>
                  <Row className="justify-content-end mt-3">
                    <Col className="col-4">
                      <a
                        href="..."
                        className="btn btn-dark"
                        onClick={(e) => {
                          e.preventDefault();
                          back();
                        }}
                      >
                        Volver
                      </a>
                    </Col>
                    <Col className="col-5">
                      <Button type="submit" className="btn btn-info">
                        Continuar
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </div>
  );
});

export default Step2;
