import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
  Spinner,
  Alert,
} from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";
import { authStore } from "store/authStore";

const Login = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const { setToken, setUser, user } = authStore();
  const [cardClasses, setCardClasses] = React.useState("card-hidden");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });
  const button_login = () => {
    setLoading(true);
    axios
      .post(`/v1/api/Account/Login`, { username: email, password: password })
      .then(({ data }) => {
        if (data.error === false) {
          Cookies.set("token", data.token, { expires: 1 });
          setToken(data.token);
          setUser(data.user);
        } else {
          setError(data.detail);
        }
      })
      .catch((e) => {
        setError(e.response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (user !== null && user !== undefined) {
      console.log('user')
      console.log(user)
      navigate(
        location.state !== null ? location.state.path : "/admin/dashboard"
      );
    }
  }, [user, navigate, location.state]);

  return (
    <Fragment>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require("assets/img/full-screen-image-2.jpg")}
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="5" md="8">
              <Card className={"card-login " + cardClasses}>
                <Card.Header>
                  <div className="header text-center">
                    <img src={require("assets/img/logo/logo.png")} alt=""></img>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Card.Body>
                    <Form.Group>
                      <label>Correo electronico</label>
                      <Form.Control
                        placeholder="Ingrese su correo"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="true"
                      />
                    </Form.Group>
                    <Form.Group>
                      <label>Contraseña</label>
                      <Form.Control
                        placeholder="Tu contraseña"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Check className="pl-0">
                      <Form.Check.Label>
                        <Form.Check.Input defaultChecked type="checkbox" />
                        <span className="form-check-sign"></span>
                        Recordar
                      </Form.Check.Label>
                    </Form.Check>
                  </Card.Body>
                </Card.Body>
                <Card.Footer className="ml-auto mr-auto text-center">
                  <Button
                    className="btn-outline btn-round btn-wd mr-1"
                    type="button"
                    variant="success"
                    onClick={button_login}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        button_login();
                      }
                    }}
                  >
                    {loading === true ? (
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden"></span>
                      </Spinner>
                    ) : (
                      "Iniciar Sesión"
                    )}
                  </Button>
                  <Row>
                    <Col md={12} sm={12}>
                      {error && (
                        <Alert color="info" className="alert-danger">
                          <span>{error}</span>
                        </Alert>
                      )}
                    </Col>
                  </Row>
                </Card.Footer>
              </Card>
            </Col>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage: "url(" + require("assets/img/bg-login.jpg") + ")",
          }}
        />
      </div>
    </Fragment>
  );
};

export default Login;
