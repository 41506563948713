import React, { useEffect, useState, Fragment } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from "react-router-dom";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Spinner
} from "react-bootstrap";
// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import clientAxios from 'tools/axiosClient';

const PaymentTypes = ( ) => {
    const MySwal = withReactContent(Swal);
    let navigate = useNavigate();
    const [ paymentTypes, setPaymentTypes ] = useState([]);
    const [ registers, setRegisters ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const handleDelete = ( paymentType ) => {
      MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          clientAxios().post(`/v1/api/PaymentTypes/Delete/${paymentType.idPayment}`).then(( { data } ) => {
            if(data.error===false){
              clientAxios().get(`/v1/api/PaymentTypes`).then(({data})=>{
                if(data.error===false){
                    setPaymentTypes(data.paymentTypes);
                }
              }).finally(()=>{
                setLoading(false);
              });
              MySwal.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success'
              )
            }
          })
        }
      });
    }
    useEffect( ( ) => {
        clientAxios().get(`/v1/api/PaymentTypes`).then(({data})=>{
            if(data.error===false){
                setPaymentTypes(data.paymentTypes);
            }
        }).finally(()=>{
          setLoading(false);
        });
    },  [ ] );
    useEffect( ( ) => {
      setRegisters( paymentTypes.map((prop, key) => {
        return {
          id: prop["idPayment"],
          name: prop["paymentName"],
          description: prop["paymentDescription"],
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a edit kind of action */}
              <Button
                onClick={() => {
                  navigate(`/admin/paymenttypes/${prop["idPayment"]}`);
                }}
                variant="warning"
                size="sm"
                className="text-warning btn-link edit"
              >
                <i className="fa fa-edit" />
              </Button>{" "}
              {/* use this button to remove the data row */}
              <Button
                onClick={(e) => handleDelete(prop)}
                variant="danger"
                size="sm"
                className="btn-link remove text-danger"
              >
                <i className="fa fa-times" />
              </Button>{" "}
            </div>
          ),
        };
      }));
    }, [ paymentTypes ])
    return (
        <Fragment>
          <Container fluid>
            <Row>
              <Col md="12">
                <Card style={{}}>
                  <Card.Header>
                    <Row>
                      <Col>
                        <Card.Title>Tipos de Pago</Card.Title>
                        <p className="category">Se muestran todos los tipos de pagos.</p>
                      </Col>
                      <Col className="text-right">
                        <Button type="submit" variant="success" onClick={(e)=>{e.preventDefault(); navigate("/admin/paymenttypes/new")}}>
                          <i className="fa fa-plus" aria-hidden="true"></i>{' '}
                          Nuevo Tipo de pagos
                        </Button>
                      </Col>
                    </Row>

                  </Card.Header>
                  <Card.Body>
                    {
                      loading === true ? (
                          <Row>
                            <Col className="text-center">
                              <Spinner animation="border" role="status">
                              </Spinner>
                            </Col>
                          </Row>

                        ) 
                      : 
                        (
                          <ReactTable
                            data={registers}
                            columns={[
                              {
                                Header: "Nombre",
                                accessor: "name",
                              },
                              {
                                Header: "Descripcion",
                                accessor: "description",
                              },
                              {
                                Header: "Acciones",
                                accessor: "actions",
                                sortable: false,
                                filterable: false,
                              },
                            ]}
                            className="-striped -highlight primary-pagination animate__animated animate__fadeIn"
                          />
                        )
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </Fragment>
    );
}
export default PaymentTypes;