import React, { useState, useEffect } from "react";
import { Navigate, useLocation, Outlet } from "react-router-dom";
import { authStore } from 'store/authStore.js';
import clientAxios from 'tools/axiosClient';
/*const getLevel = (role) => {
    if (role === 'Administrador') {
        return 3;
    }
    else if (role === 'Empleado') {
        return 2;
    }
    else {
        return 0;
    }
}*/
const Authorization = ({ permissions }) => {
    const { user } = authStore();
    const location = useLocation();
    //console.log('user')
    //console.log(user)
    if (user !== null && user!== undefined) {
        //const level = getLevel(user.idRole);
        const isAllowed = permissions.some( ( allowed ) => allowed ===  user.idRol.toString() || allowed === 'AllowAnonymous' );
        return isAllowed ? <Outlet /> : <Navigate to='/Error/403' replace/>;
    }
    else {
        const isAllowed = permissions.some( ( allowed ) => allowed === 'AllowAnonymous' );
        if ( isAllowed ) {
            return <Outlet />;
        }
        else{
            return <Navigate to='/auth/Login' state={ { path: location.pathname } } replace />;
        }
    }
};

export default Authorization;
