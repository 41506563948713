import React, { Fragment, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Card,
  Table,
  Row,
  Col,
  FormGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import { Formik, Form } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import clientAxios from "tools/axiosClient";

const WorkItems = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const MySwal = withReactContent(Swal);
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  const [project, setProject] = useState({
    idProject: 0,
    projectName: "",
    projectDescription: "",
    price: 0,
    time: 0,
    timeType: "",
    projectType: "",
  });
  const [workItem, setWorkItem] = useState({
    idProject: 0,
    workItemName: "",
    workItemDescription: "",
    time: 0,
    timeType: "",
  });

  const [update, setUpdate] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [updateTime, setUpdateTime] = useState(0);
  const [taskTime, setTaskTime] = useState(0);

  const [workItems, setWorkItems] = useState([]);
  const time_types = [
    { value: "dias", label: "Días" },
    { value: "semanas", label: "Semanas" },
  ];

  const handleSubmit = (values) => {
    //Verificamos si aun no se excede el tiempo máximo designado al proyecto
    if (currentTime < project.time) {
      values.idProject = project.idProject;
      clientAxios()
        .post(`/v1/api/WorkItems/Create`, values)
        .then(({ data }) => {
          if (data.error === false) {
            Toast.fire({
              icon: "success",
              title: "Tarea creada con éxito.",
            });
            getProject(project.idProject);
            setUpdate(false);
            setWorkItem({
              idProject: 1,
              workItemName: "",
              workItemDescription: "",
              time: 0,
              timeType: "",
            });
          }
        });
    } else {
      MySwal.fire({
        title:
          "<h2>¡Sin tiempo!</h2><p>El tiempo máximo del proyecto no puede ser excedido.</p>",
        icon: "warning",
        showConfirmButton: true,
      });
    }
  };
  const handleSubmitUpdate = (values) => {
    const time = parseInt(updateTime) + parseInt(values.time);
    setUpdateTime(time);
    if (time <= project.time) {
      values.idProject = project.idProject;
      clientAxios()
        .post(`/v1/api/WorkItems/Update`, values)
        .then(({ data }) => {
          if (data.error === false) {
            Toast.fire({
              icon: "success",
              title: "Tarea actualizada con éxito.",
            });
            setUpdate(false);
            getProject(id);
          }
        });
    } else {
      const time = parseInt(project.time) - parseInt(taskTime);
      setUpdateTime(time);
      MySwal.fire({
        title:
          "<h2>¡Sin tiempo!</h2><p>El tiempo máximo del proyecto no puede ser excedido.</p>",
        icon: "warning",
        showConfirmButton: true,
      });
    }
  };
  const handleEdit = (item) => {
    setUpdateTime(currentTime - item.time);
    setTaskTime(item.time);
    setWorkItem(item);
    setUpdate(true);
  };
  const handleDelete = (item) => {
    MySwal.fire({
      title: "¿Está seguro de eliminar la tarea?",
      text: "¡No podrá revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
    }).then((result) => {
      if (result.isConfirmed) {
        clientAxios()
          .post(`/v1/api/WorkItems/Delete/${item.idWorkItem}`)
          .then(({ data }) => {
            getProject(id);
            if (data.error === false) {
              Toast.fire({
                icon: "success",
                title: "Tarea eliminada con éxito.",
              });
            }
          });
      }
    });
  };
  const getProject = (id) => {
    clientAxios()
      .get(`/v1/api/Projects/${id}`)
      .then(({ data }) => {
        if (data.error === false) {
          setProject(data.project);
          setWorkItems(data.workItems);
          setWorkItem({
            idProject: 1,
            workItemName: "",
            workItemDescription: "",
            time: 0,
            timeType: data.project.timeType,
          });
          setCurrentTime(
            data.workItems.reduce(
              (acc, element) => acc + parseInt(element.time),
              0
            )
          );
        }
      });
  };
  useEffect(() => {
    if (id !== "") {
      getProject(id);
    }
  }, []);
  return (
    <Row>
      <Col className="col-12">
        <Card className="strpied-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Agregar Tareas</Card.Title>
            <p className="card-category">
              Se muestran los detalles del proyecto y las tareas requeridas.
            </p>
          </Card.Header>
          <Card.Body className="table-responsive p-0">
            <Formik
              enableReinitialize={true}
              initialValues={workItem}
              onSubmit={update ? handleSubmitUpdate : handleSubmit}
            >
              {({ values, errors, touched, handleChange, resetForm }) => (
                <Form>
                  <Row className="justify-content-center m-3">
                    <Col md="4">
                      Projecto
                      <Select
                        name="type"
                        value={{
                          value: project.idProject,
                          label: project.projectName,
                        }}
                        onChange={(e) => {
                          handleChange({
                            target: { value: e.value, name: "idProject" },
                          });
                        }}
                        options={[
                          {
                            value: project.idProject,
                            label: project.projectName,
                          },
                        ]}
                        placeholder="Selecciona una tipo"
                        disabled="true"
                      />
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Nombre</label>
                        <FormControl
                          type="text"
                          value={values.workItemName}
                          name="workItemName"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Descripcion</label>
                        <FormControl
                          type="text"
                          value={values.workItemDescription}
                          name="workItemDescription"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="m-3">
                    <Col md="4">
                      <FormGroup>
                        <label>Tiempo</label>
                        <FormControl
                          type="text"
                          value={values.time}
                          name="time"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <label>Tipo de tiempo</label>
                        <Select
                          name="timeType"
                          placeholder="Selecciona una unidad"
                          value={time_types.map((time) => {
                            if (time.value === values.timeType)
                              return { value: time.value, label: time.label };
                          })}
                          options={time_types}
                          onChange={(e) => {
                            handleChange({
                              target: { value: e.value, name: "timeType" },
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      {update ? (
                        <Fragment>
                          <br />
                          <Button variant="warning" type="submit">
                            <i className="fa fa-pen" aria-hidden="true"></i>{" "}
                            Actualizar Tarea
                          </Button>{" "}
                          <Button
                            variant="danger"
                            type="submit"
                            onClick={(e) => {
                              e.preventDefault();
                              setUpdate(false);
                              setWorkItem({
                                idProject: 1,
                                workItemName: "",
                                workItemDescription: "",
                                time: 0,
                                timeType: "",
                              });
                            }}
                          >
                            <i className="fa fa-times" aria-hidden="true"></i>{" "}
                            Cancelar
                          </Button>
                        </Fragment>
                      ) : (
                        <Fragment>
                          <br />
                          <Button variant="warning" type="submit">
                            <i className="fa fa-plus" aria-hidden="true"></i>{" "}
                            Agregar Tarea
                          </Button>
                        </Fragment>
                      )}
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
            <Row className="m-3">
              <Col md={4}>
                <Button
                  variant="secondary"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/admin/Projects");
                  }}
                >
                  <i className="fa fa-arrow-left" aria-hidden="true"></i> Atrás
                </Button>
              </Col>
              <Col md={4} className="text-center">
                <h3>
                  <span className="text-info">
                    {currentTime} {project.timeType}
                  </span>{" "}
                  /{" "}
                  <span className="text-success">
                    {project.time} {project.timeType}{" "}
                    <small className="text-success">disponibles</small>
                  </span>
                </h3>
              </Col>
            </Row>
            <Row className="m-3">
              <h4>Lista de tareas</h4>
              <Table className="table-hover table-striped w-full">
                <thead>
                  <tr>
                    <th>Nombre</th>
                    <th>Descripción</th>
                    <th>Tiempo</th>
                    <th>Unidad de Tiempo</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {workItems.map((i) => (
                    <tr key={i.idWorkItem}>
                      <td>{i.workItemName}</td>
                      <td>{i.workItemDescription}</td>
                      <td>{i.time}</td>
                      <td>{i.timeType}</td>
                      <td className="text-right">
                        <Button
                          className="text-warning btn-link edit"
                          type="submit"
                          variant="warning"
                          size="sm"
                          onClick={(e) => handleEdit(i)}
                        >
                          <i className="fa fa-edit" />
                        </Button>
                        <Button
                          className="text-danger btn-link edit"
                          type="submit"
                          variant="danger"
                          size="sm"
                          onClick={(e) => handleDelete(i)}
                        >
                          <i className="fa fa-times" />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
export default WorkItems;
