import React from "react";
import { useNavigate } from "react-router-dom";
const Error403 = ( ) => {
    const navigate = useNavigate();
    return (
        <main className="main-content  mt-0">
        <div className="page-header align-items-start min-vh-100" style={{height:'100vh',position: 'relative', backgroundImage: "url(" + require("assets/img/bg-login.jpg") + ")", backgroundRepeat:"no-repeat", backgroundSize:"cover", backgroundAttachment:"fixed"}}>
          <span className="mask bg-gradient-dark opacity-6"></span>
          <div className="container my-auto">
            <div className="row">
              <div className="col-lg-12 m-auto text-center">
                <h1 className="display-1 text-bolder text-white">Error 403</h1>
                <h2 className="text-white">Acceso Denegado</h2>
                <h3 className="lead text-white">No cuenta con permisos suficientes para ingresar a esta sección.</h3>
                            <a href="/" className="btn btn-outline-white mt-4" onClick={e => { e.preventDefault(); window.history.back(); }}>Volver</a>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
}
export default Error403;