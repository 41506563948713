import React from "react";

// react-bootstrap components
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Form,
  InputGroup,
  Navbar,
  Nav,
  Pagination,
  Container,
  Row,
  Col,
} from "react-bootstrap";

function AdminFooter() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className="footer">
        <Container fluid className="pl-4 ml-2">
          <nav>
            <ul className="footer-menu"></ul>
            <p className="copyright text-center">
              © CimaAdmin {process.env.REACT_APP_VERSION} - {currentYear} por{" "}
              <a target="_blank" href="https://www.devexstudio.com.mx">
                DevexStudio
              </a>
            </p>
          </nav>
        </Container>
      </footer>
    </>
  );
}

export default AdminFooter;
