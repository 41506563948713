import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Table,
  Row,
  Col,
  FormGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import Select from "react-select";
import { Formik, Form } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import clientAxios from "tools/axiosClient";

const Step4 = React.forwardRef((props, ref) => {
  const { form, setForm, idOrder } = props;
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [project, setProject] = useState({
    idProject: 0,
    projectName: "",
    projectDescription: "",
    price: 0,
    time: 0,
    timeType: "",
    projectType: "",
  });
  const [workItem, setWorkItem] = useState({
    idProject: 1,
    workItemName: "",
    workItemDescription: "",
    time: 0,
    timeType: "",
  });
  const [update, setUpdate] = useState(false);
  const [workItems, setWorkItems] = useState([]);
  const time_types = [
    { value: "dias", label: "Días" },
    { value: "horas", label: "Horas" },
  ];
  const handleSubmit = (values) => {
    values.ProjectOrderId=idOrder;
    console.log(values);
    clientAxios().post(`/v1/api/WorkItemsByOrder/Create`, values).then(({ data }) => {
      if (data.error === false) {
        MySwal.fire(
          "Creado!",
          "El proyecto ha sido creado con éxito.",
          "success"
        ).then(() => {
          setForm({
            ...form,
            idWorkItemOrder: 0,
            workItemName:'',
            time:'',
            timeType: ''
          })
          getWorkItems();
        });
      }
    });
  };
  const handleSubmitUpdate = (values) => {
    clientAxios().post(`/v1/api/WorkItemsByOrder/Update`, values).then(({ data }) => {
      if (data.error === false) {
        getProject(form.ProjectId);
        MySwal.fire(
          "Actualizado!",
          "Las tareas han sido actualizadas con éxito.",
          "success"
        ).then(() => {
          getWorkItems();
          setUpdate(false);
          setWorkItem({
            idProject: 1,
            workItemName: "",
            time: 0,
            timeType: "",
          });
          setForm({
            ...form,
            idWorkItemOrder: 0,
            workItemName:'',
            time:'',
            timeType: ''
          })
        });
      }
    });
  };
  const handleEdit = (item) => {
    setWorkItem(item);
    setForm({
      ...form,
      idWorkItemOrder: item.idWorkItemOrder,
      workItemName:item.workItemName,
      time:item.time,
      timeType: item.timeType
    })
    console.log(item);
    setUpdate(true);
  };
  const handleDelete = (item) => {
    MySwal.fire({
      title: "¿Está seguro de eliminar la tarea?",
      text: "¡No podrá revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, eliminar!",
    }).then((result) => {
      if (result.isConfirmed) {
        clientAxios().post(`/v1/api/WorkItemsByOrder/Delete/${item.idWorkItemOrder}`).then(({ data }) => {
          getWorkItems();
          if (data.error === false) {
            MySwal.fire(
              "Eliminado!",
              "La tarea ha sido eliminada.",
              "success"
            );
          }
        });
      }
    });
  };
  const getProject = (id) => {
    console.log('getProject');
    clientAxios().get(`/v1/api/ProjectsOrders/${id}`).then(({ data }) => {
      if (data.error === false) {
        setProject(data.projectOrder);
        setWorkItems(data.workItems);
        console.log(data);
        setWorkItem({ ...workItem, idProject: data.projectOrder.idProject });
      }
    });
  };
  const getWorkItems = () => {
    clientAxios().get(`/v1/api/ProjectsOrders/WorkItems/${idOrder}`).then(({ data }) => {
      if (data.error === false) {
        setWorkItems(data.workItems);
        console.log(data);
        //setWorkItem({ ...workItem, idProject: data. });
      }
    });
  };
  useEffect (()=>{
    if(form.ProjectId !== "" && form.projectId !== undefined && form.projectId !== null) getWorkItems();
  }, []);
  useEffect(() => {
    if (idOrder !== "" && idOrder !== undefined && idOrder !== null) {
      getProject(idOrder);
    }
  }, [idOrder]);

  const back = () => {
    const percentTab = document.getElementById(
      "page-subcategories-tabs-example-tab-percent-tab"
    );
    const percentContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-percent-tab"
    );
    const timeTab = document.getElementById(
      "page-subcategories-tabs-example-tab-time-tab"
    );
    const timeContent = document.getElementById(
      "page-subcategories-tabs-example-tabpane-time-tab"
    );
    percentTab.classList.remove("active");
    percentContent.classList.remove("active", "show");
    timeTab.classList.add("active");
    timeContent.classList.add("active", "show");
  };

  return (
    <Fragment>
      <div className="wizard-step" ref={ref}>
        <Row>
          <Col className="ml-auto mr-auto" md="12">
            <Card.Body className="table-responsive p-0">
              <Formik
                enableReinitialize={true}
                initialValues={workItem}
                onSubmit={update ? handleSubmitUpdate : handleSubmit}
              >
                {({ values, errors, touched, handleChange }) => (
                  <Form>
                    <Row className="justify-content-center m-3">
                      <Col md="4">
                        Projecto
                        <Select
                          name="type"
                          value={{
                            value: project.idOrder,
                            label: project.projectByClientDescription,
                          }}
                          onChange={(e) => {
                            handleChange({
                              target: { value: e.value, name: "idProject" },
                            });
                          }}
                          options={[
                            {
                              value: project.idOrder,
                              label: project.projectByClientDescription,
                            },
                          ]}
                          placeholder="Selecciona una tipo"
                          disabled="true"
                        />
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label>Nombre</label>
                          <FormControl
                            type="text"
                            value={values.workItemName}
                            name="workItemName"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label>Descripcion</label>
                          <FormControl
                            type="text"
                            value={values.workItemDescription}
                            name="workItemDescription"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="m-3">
                      <Col md="4">
                        <FormGroup>
                          <label>Tiempo</label>
                          <FormControl
                            type="text"
                            value={values.time}
                            name="time"
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label>Tipo de tiempo</label>
                          <Select
                            name="timeType"
                            placeholder="Selecciona una unidad"
                            value={time_types.map((time) => {
                              if (time.value === values.timeType)
                                return {
                                  value: time.value,
                                  label: time.label,
                                };
                            })}
                            options={time_types}
                            onChange={(e) => {
                              handleChange({
                                target: { value: e.value, name: "timeType" },
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        {update ? (
                          <Fragment>
                            <br />
                            <Button variant="warning" size="sm" type="submit">
                              Actualizar Tarea
                            </Button>{" "}
                            <Button
                              variant="danger"
                              size="sm"
                              type="submit"
                              onClick={(e) => {
                                e.preventDefault();
                                setUpdate(false);
                                setWorkItem({
                                  idProject: 1,
                                  workItemName: "",
                                  workItemDescription: "",
                                  time: 0,
                                  timeType: "",
                                });
                              }}
                            >
                              Cancelar
                            </Button>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <br />
                            <Col className="col-5">
                              <button type="submit" className="btn btn-warning">
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>{" "}
                                Agregar
                              </button>
                            </Col>
                          </Fragment>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
              <Row className="m-3">
                <Col>
                  <Button
                    variant="secondary"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      back();
                    }}
                  >
                    Volver
                  </Button>
                </Col>
              </Row>
              <Row className="m-3">
                <h4>Lista de tareas</h4>
                <Table className="table-hover table-striped w-full">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Descripción</th>
                      <th>Tiempo</th>
                      <th>Unidad de Tiempo</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {workItems.map((i) => (
                      <tr key={i.idWorkItem}>
                        <td>{i.workItemName}</td>
                        <td>{i.workItemDescription}</td>
                        <td>{i.time}</td>
                        <td>{i.timeType}</td>
                        <td className="text-right">
                          <Button
                            className="text-warning btn-link edit"
                            type="submit"
                            variant="warning"
                            size="sm"
                            onClick={(e) => handleEdit(i)}
                          >
                            <i className="fa fa-edit" />
                          </Button>
                          <Button
                            className="text-danger btn-link edit"
                            type="submit"
                            variant="danger"
                            size="sm"
                            onClick={(e) => handleDelete(i)}
                          >
                            <i className="fa fa-times" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            </Card.Body>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
});

export default Step4;
